// Export a Ionic Button component styled with the Ionic CSS utilities.

import React from 'react';
import { IonButton, IonIcon, IonSpinner } from '@ionic/react';
import './styles.css';

interface AppButtonProps extends React.ComponentProps<typeof IonButton> {
  variant?: 'primary' | 'secondary';
  fill?: 'solid' | 'outline' | undefined;
  onClick?: () => void;
  label: string;
  icon?: string;
  loading?: boolean;
}

export const AppButton = ({
  variant = 'primary',
  label,
  fill,
  onClick,
  icon,
  loading,
  ...props
}: AppButtonProps) => {
  return (
    <IonButton
      expand="block"
      onClick={onClick}
      fill={variant === 'secondary' ? 'outline' : undefined}
      className={`app-button app-button-${variant} ${loading ? 'app-button-loading' : ''}`}
      {...props}
    >
      {icon && <IonIcon className="app-button-icon" slot="icon-only" icon={icon}></IonIcon>}
      {!loading && label}
      {loading && <IonSpinner style={{ marginLeft: '8px' }} name="circular"></IonSpinner>}
    </IonButton>
  );
};
