// PhotoOptionWithSkipScreen.tsx
import React from 'react';
import { IonCard, IonContent, IonIcon, IonPage, IonText, useIonRouter } from '@ionic/react';
import './styles.css';
import { AppButton } from '../../components/App/Button';
import { AppTitle } from '../../components/App/Title';
import { informationCircle } from 'ionicons/icons';
import IconHeader from '../../components/App/IconHeader';
import takePictureImg from '../../assets/take-picture-img1.png';

const PhotoOptionWithSkipScreen: React.FC = () => {
  const router = useIonRouter();

  const handleContinue = () => {
    router.push('/answer-questions');
  };

  const handleSkip = () => {
    router.push('/successful-registration');
  };

  return (
    <IonPage>
      <IonContent color={'dark'} fullscreen>
        <div className="photo-flex-container">
          <div className="photo-background-container"></div>

          <div className="photo-action-container">
            <IconHeader />
            <div className="form-container">
              <AppTitle
                topTitle="Finalize seu cadastro com o"
                title="Reconhecimento facial e cadastro de documentos"
              />
              <div className="photo-button-container">
                <div className="photo-img-container">
                  <img src={takePictureImg} alt="Take Picture Image" />
                </div>
                <IonCard className="info-card">
                  <IonIcon icon={informationCircle} className="info-icon" size="large" />
                  <IonText className="info-text">
                    Não se preocupe você apenas precisa fazer o cadastro facial uma vez.
                  </IonText>
                </IonCard>
              </div>
              <AppButton label="Continuar" onClick={handleContinue} />
              <AppButton label="Pular" variant="secondary" onClick={handleSkip} />
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default PhotoOptionWithSkipScreen;
