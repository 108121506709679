import React from 'react';
import { IonText } from '@ionic/react';
import './index.css';

export const AppFormError = ({ error }: { error: string }) => {
  return (
    <IonText
      style={{
        display: error ? 'block' : 'none',
      }}
      className="app-form-error"
    >
      {error}
    </IonText>
  );
};
