// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-input {
	font-size: 1.25rem;

}

.app-input .native-wrapper{
	border-color: var(--app-color-support-primary);
	font-size: 1rem;
	border-width: thin;
	border-style: solid;
	border-radius: 4px;
	margin-top: 0.75rem;
	padding:0.5rem;
	background-color: var(--app-color-on-primary)
}`, "",{"version":3,"sources":["webpack://./src/components/App/MaskedInput/styles.css"],"names":[],"mappings":"AAAA;CACC,kBAAkB;;AAEnB;;AAEA;CACC,8CAA8C;CAC9C,eAAe;CACf,kBAAkB;CAClB,mBAAmB;CACnB,kBAAkB;CAClB,mBAAmB;CACnB,cAAc;CACd;AACD","sourcesContent":[".app-input {\r\n\tfont-size: 1.25rem;\r\n\r\n}\r\n\r\n.app-input .native-wrapper{\r\n\tborder-color: var(--app-color-support-primary);\r\n\tfont-size: 1rem;\r\n\tborder-width: thin;\r\n\tborder-style: solid;\r\n\tborder-radius: 4px;\r\n\tmargin-top: 0.75rem;\r\n\tpadding:0.5rem;\r\n\tbackground-color: var(--app-color-on-primary)\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
