// CreateAccountScreen.tsx
import React, { useState } from 'react';
import { IonContent, IonPage, useIonRouter } from '@ionic/react';
import './styles.css';
import { AppButton } from '../../components/App/Button';
import { AppInput } from '../../components/App/Input';
import { AppTitle } from '../../components/App/Title';
import IconHeader from '../../components/App/IconHeader';
import { createUser } from '../../api/User';
import { login } from '../../api/auth';
import { setAccessToken, setRefreshToken } from '../../redux/auth';
import { useDispatch } from 'react-redux';
import { setUserId } from '../../redux/auth';
import { useFormError } from '../../hooks/useFormError';
import { AppFormError } from '../../components/App/FormError';
import { validateFieldsFilling } from '../../utils';
import { useLoading } from '../../hooks/useLoading';

const FIELDS = {
  email: 'email',
  password: 'password',
  confirmPassword: 'confirm',
};

const CreateAccountScreen: React.FC = () => {
  const router = useIonRouter();

  const { changeLoadingStatus, loading } = useLoading();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [confirm, setConfirmPassword] = useState<string>('');
  const dispatch = useDispatch();
  const {
    getFieldError,
    generalFormError,
    setFieldError,
    clearFieldsErrors,
    setGeneralFormError,
    clearGeneralFormError,
  } = useFormError({
    initialFields: [FIELDS.email, FIELDS.password, FIELDS.confirmPassword],
  });

  const handleCreateAccount = async () => {
    clearGeneralFormError();
    clearFieldsErrors();
    const allFilled = validateFieldsFilling(
      [
        { fieldId: FIELDS.email, value: email },
        { fieldId: FIELDS.password, value: password },
        { fieldId: FIELDS.confirmPassword, value: confirm },
      ],
      setFieldError,
    );
    if (!allFilled) {
      return;
    }
    if (confirm !== password) {
      setFieldError(FIELDS.password, ' ');
      setFieldError(FIELDS.confirmPassword, 'As senhas não coincidem.');
      return;
    }
    changeLoadingStatus();
    const createdUser = await createUser({ email, password });
    if (createdUser === null) {
      changeLoadingStatus();
      setGeneralFormError('Erro ao criar sua conta. Verifique seus dados.');
      return;
    }
    if (createdUser.message?.includes(`${email} já existe`)) {
      changeLoadingStatus();
      setFieldError(FIELDS.email, 'E-mail já cadastrado.');
      return;
    }
    const loggedUserTokens = await login(email, password);
    changeLoadingStatus();
    if (loggedUserTokens === null) {
      setGeneralFormError('Erro ao autenticar sua conta.');
      return;
    }
    dispatch(setUserId(createdUser.id));
    dispatch(setAccessToken(loggedUserTokens.access_token));
    dispatch(setRefreshToken(loggedUserTokens.refresh_token));
    router.push('/account-information', 'none');
  };

  return (
    <IonPage>
      <IonContent color={'dark'} fullscreen>
        <div className="register-flex-container">
          <div className="register-background-container"></div>

          <div className="register-action-container">
            <IconHeader />
            <div className="form-container">
              <AppTitle title="Criar sua conta" topTitle="Preencha as informações para" />
              <div className="register-button-container">
                <AppInput
                  required
                  label="Email"
                  placeholder="Digite seu email"
                  onChange={(e) => setEmail(e.detail.value ?? '')}
                  error={getFieldError(FIELDS.email)}
                  value={email}
                />
                <AppInput
                  required
                  label="Senha"
                  placeholder="Digite sua senha"
                  type="password"
                  onChange={(e) => setPassword(e.detail.value ?? '')}
                  error={getFieldError(FIELDS.password)}
                  value={password}
                />
                <AppInput
                  required
                  label="Confirme sua senha"
                  placeholder="Digite sua senha novamente"
                  type="password"
                  onChange={(e) => setConfirmPassword(e.detail.value ?? '')}
                  error={getFieldError(FIELDS.confirmPassword)}
                  value={confirm}
                />
              </div>
              <AppButton label="Criar conta" onClick={handleCreateAccount} loading={loading} />
              {generalFormError && <AppFormError error={generalFormError} />}
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default CreateAccountScreen;
