import React, { useEffect, useState } from 'react';
import { IonContent, IonIcon, IonPage, IonText, useIonRouter } from '@ionic/react';
import './style.css';
import { AppTitle } from '../../components/App/Title';
import { AppInput } from '../../components/App/Input';
import { AppButton } from '../../components/App/Button';
import { chevronBack } from 'ionicons/icons';
import { getUserById, updateUserById } from '../../api/User';
import { InputUpdateUserByIdDto } from '../../api/User/dto';
import { getCountries, getStatesByCountryId } from '../../api/country';
import { getCitiesByStateId } from '../../api/state';
import { AppFormError } from '../../components/App/FormError';
import { AppMaskedInput } from '../../components/App/MaskedInput';
import AppSelect from '../../components/App/Select';
import { SelectItem } from '../../components/App/Select/types';
import { useLoading } from '../../hooks/useLoading';
import { validateFieldsFilling, isValidCpfFormat, removeCpfMask } from '../../utils';
import { useFormError } from '../../hooks/useFormError';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { getCity } from '../../api/city';

const FIELDS = {
  name: 'name',
  surname: 'surname',
  cpf: 'cpf',
  country: 'country',
  state: 'state',
  city: 'city',
};

const UserPersonalDataScreen: React.FC = () => {
  const router = useIonRouter();
  const { changeLoadingStatus, loading } = useLoading();
  const userAuthData = useSelector((state: RootState) => state.auth);
  const {
    generalFormError,
    setGeneralFormError,
    getFieldError,
    setFieldError,
    clearFieldsErrors,
    clearGeneralFormError,
  } = useFormError({
    initialFields: [
      FIELDS.name,
      FIELDS.surname,
      FIELDS.cpf,
      FIELDS.country,
      FIELDS.state,
      FIELDS.city,
    ],
  });

  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [cpf, setCpf] = useState('');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [country, setCountry] = useState('');

  const [cities, setCities] = useState<SelectItem[]>([]);
  const [countries, setCountries] = useState<SelectItem[]>([]);
  const [states, setStates] = useState<SelectItem[]>([]);

  useEffect(() => {
    const fetchUserData = async () => {
      changeLoadingStatus();
      const user = await getUserById(userAuthData.userId!, userAuthData.accessToken!);
      if (user === null) {
        console.log("Error fetching user's data");
        changeLoadingStatus();
        return;
      }
      user.first_name && setName(user.first_name);
      user.last_name && setSurname(user.last_name);
      user.document && setCpf(user.document);
      if (user.city_id) {
        const city = await getCity(user.city_id!);
        if (city === null) {
          changeLoadingStatus();
          return;
        }
        setCountry(city.state.country_id.toString());
        setState(city.state_id.toString());
        setCity(city.id.toString());
      }
      changeLoadingStatus();
    };
    fetchUserData();
  }, []);

  useEffect(() => {
    const fetchCountries = async () => {
      changeLoadingStatus();
      const countries = await getCountries();
      changeLoadingStatus();
      if (countries === null) {
        console.log('Error fetching countries');
        return;
      }
      const mappedCountries: SelectItem[] = countries.map((country) => ({
        text: country.name,
        value: country.id.toString(),
      }));
      setCountries(mappedCountries);
    };
    fetchCountries();
  }, []);

  useEffect(() => {
    if (!country) {
      return;
    }
    const fetchStates = async () => {
      changeLoadingStatus();
      const states = await getStatesByCountryId(+country);
      changeLoadingStatus();
      if (states === null) {
        console.log('Error fetching states');
        return;
      }
      const mappedStates: SelectItem[] = states.map((state) => ({
        text: state.name,
        value: state.id.toString(),
      }));
      setStates(mappedStates);
    };
    fetchStates();
  }, [country]);

  useEffect(() => {
    if (!state) {
      return;
    }
    const fetchCities = async () => {
      changeLoadingStatus();
      const cities = await getCitiesByStateId(+state);
      changeLoadingStatus();
      if (cities === null) {
        console.log('Error fetching cities');
        return;
      }
      const mappedCities: SelectItem[] = cities.map((state) => ({
        text: state.name,
        value: state.id.toString(),
      }));
      setCities(mappedCities);
    };
    fetchCities();
  }, [state]);

  const getUpdateUserInputDto = (): InputUpdateUserByIdDto => {
    return {
      first_name: name,
      last_name: surname,
      document: removeCpfMask(cpf),
      city_id: +city,
    };
  };

  const validateAllFieldsFilling = () => {
    clearFieldsErrors();
    clearGeneralFormError();
    return validateFieldsFilling(
      [
        { fieldId: FIELDS.name, value: name },
        { fieldId: FIELDS.cpf, value: cpf },
        { fieldId: FIELDS.country, value: country },
        { fieldId: FIELDS.state, value: state },
        { fieldId: FIELDS.city, value: city },
      ],
      setFieldError,
    );
  };

  const handleSave = async () => {
    const allFilled = validateAllFieldsFilling();
    const isValidCpf = isValidCpfFormat(cpf);
    if (!isValidCpf) {
      setFieldError(FIELDS.cpf, 'Campo com valor inválido');
    }
    if (!allFilled || !isValidCpf) {
      return;
    }
    changeLoadingStatus();
    const updateResponse = await updateUserById(
      userAuthData.userId!,
      getUpdateUserInputDto(),
      userAuthData.accessToken!,
    );
    changeLoadingStatus();
    if (updateResponse === null) {
      setGeneralFormError('Ocorreu um erro ao atualizar o cadastro.');
      return;
    }
    router.push('/my-tickets');
  };

  const handleCancel = () => {
    router.push('/my-tickets', 'none');
  };

  const handleBack = () => {
    router.push('/my-tickets', 'none');
  };

  return (
    <IonPage>
      <IonContent color={'dark'} fullscreen>
        <div className="personal-flex-container">
          <div className="personal-background-container"></div>

          <div className="personal-action-container">
            <div className="back-button">
              <IonIcon src={chevronBack} color="white" size="large" onClick={handleBack} />
              <IonText>Voltar para meus pedidos</IonText>
            </div>
            <div className="form-container">
              <AppTitle title="Dados pessoais" topTitle="Revisar seus" />
              <div className="register-button-container">
                <AppInput
                  label="Nome*"
                  value={name}
                  onChange={(e) => setName(e.detail.value!)}
                  placeholder={'Informe seu nome'}
                  required
                  error={getFieldError(FIELDS.name)}
                />
                <AppInput
                  label="Sobrenome"
                  value={surname}
                  onChange={(e) => setSurname(e.detail.value!)}
                  placeholder="Informe seu sobrenome"
                  required
                  error={getFieldError(FIELDS.surname)}
                />
                <AppMaskedInput
                  mask="cpf"
                  label="CPF*"
                  value={cpf}
                  onChange={(e) => setCpf(e.detail.value!)}
                  placeholder="Informe seu CPF"
                  required
                  error={getFieldError(FIELDS.cpf)}
                />
                <AppSelect
                  items={countries}
                  label="País*"
                  placeholder="Selecione seu país"
                  selectedItem={country}
                  onChange={() => {
                    setState('');
                    setCity('');
                  }}
                  setSelectedItem={setCountry}
                  disabled={!countries.length}
                  error={getFieldError(FIELDS.country)}
                />
                <AppSelect
                  items={states}
                  label="Estado*"
                  placeholder="Selecione seu estado"
                  selectedItem={state}
                  setSelectedItem={setState}
                  onChange={() => setCity('')}
                  disabled={!country || !states.length}
                  error={getFieldError(FIELDS.state)}
                />
                <AppSelect
                  items={cities}
                  label="Cidade*"
                  placeholder="Selecione sua cidade"
                  selectedItem={city}
                  setSelectedItem={setCity}
                  disabled={!state || !cities.length}
                  error={getFieldError(FIELDS.city)}
                />
              </div>
              <AppButton label="Salvar" onClick={handleSave} loading={loading} />
              {generalFormError && <AppFormError error={generalFormError} />}
              <AppButton label="Cancelar" variant="secondary" onClick={handleCancel} />
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default UserPersonalDataScreen;
