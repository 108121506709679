// LoginScreen.tsx
import React, { useState } from 'react';
import { IonButton, IonContent, IonIcon, IonPage, IonText, useIonRouter } from '@ionic/react';
import './styles.css';
import { AppButton } from '../../components/App/Button';
import { AppInput } from '../../components/App/Input';
import { AppTitle } from '../../components/App/Title';
import { close } from 'ionicons/icons';
import { login } from '../../api/auth';
import { useDispatch } from 'react-redux';
import { setAccessToken, setRefreshToken, setUserId } from '../../redux/auth';
import { useFormError } from '../../hooks/useFormError';
import { validateFieldsFilling } from '../../utils';
import { AppFormError } from '../../components/App/FormError';
import { useLoading } from '../../hooks/useLoading';

const FIELDS = {
  email: 'email',
  password: 'password',
};

const LoginScreen: React.FC = () => {
  const router = useIonRouter();
  const dispatch = useDispatch();
  const {
    getFieldError,
    setFieldError,
    setGeneralFormError,
    generalFormError,
    clearFieldsErrors,
    clearGeneralFormError,
  } = useFormError({
    initialFields: [FIELDS.email, FIELDS.password],
  });
  const { changeLoadingStatus, loading } = useLoading();

  const handleLogin = async () => {
    clearFieldsErrors();
    clearGeneralFormError();
    const allFilled = validateFieldsFilling(
      [
        { fieldId: FIELDS.email, value: email },
        { fieldId: FIELDS.password, value: password },
      ],
      setFieldError,
    );
    if (!allFilled) {
      return;
    }
    changeLoadingStatus();
    const res = await login(email, password);
    changeLoadingStatus();
    if (res === null) {
      setGeneralFormError('Ocorreu um erro. Verifique suas credenciais e tente novamente.');
      return;
    }
    dispatch(setUserId(res.user_id));
    dispatch(setAccessToken(res.access_token));
    dispatch(setRefreshToken(res.refresh_token));
    router.push('/my-tickets', 'none');
  };

  const handleCreateAccount = () => {
    router.push('/create-account', 'none');
  };

  const handlePasswordRecovery = () => {
    router.push('/password-recovery', 'none');
  };

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  return (
    <IonPage>
      <IonContent color={'dark'} fullscreen>
        <div className="login-flex-container">
          <div className="login-background-container"></div>

          <div className="login-action-container">
            <div
              style={{
                width: '40px',
              }}
            >
              <IonButton fill="clear" onClick={() => router.goBack()} className="back-button">
                <IonIcon icon={close}></IonIcon>
              </IonButton>
            </div>
            <div className="form-container">
              <AppTitle invert title="Entre com suas credenciais" topTitle="Faça seu login" />
              <div className="login-button-container">
                <AppInput
                  required
                  label="Email"
                  placeholder="Digite seu email"
                  onChange={(e) => setEmail(e.detail.value ?? '')}
                  error={getFieldError(FIELDS.email)}
                  value={email}
                />
                <AppInput
                  required
                  label="Senha"
                  placeholder="Digite sua senha"
                  type="password"
                  error={getFieldError(FIELDS.password)}
                  onChange={(e) => setPassword(e.detail.value ?? '')}
                  value={password}
                />
                <div
                  style={{
                    width: '100%',
                    textAlign: 'right',
                    color: '#9ED0E6',
                    marginTop: 5,
                  }}
                >
                  <IonText onClick={handlePasswordRecovery} style={{ cursor: 'pointer' }}>
                    Recuperar senha
                  </IonText>
                </div>
              </div>
              <AppButton label="Entrar" onClick={handleLogin} loading={loading} />
              {generalFormError && <AppFormError error={generalFormError} />}
              <div
                style={{
                  width: '100%',
                  textAlign: 'center',
                  marginTop: '1.5rem',
                }}
              >
                <IonText className="login-link" onClick={handleCreateAccount}>
                  Não tem cadastro?
                  <IonText>
                    <span style={{ color: '#9ED0E6' }}> Crie sua conta!</span>
                  </IonText>
                </IonText>
              </div>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default LoginScreen;
