import React, { useRef, useState } from 'react';
import { IonContent, IonIcon, IonPage, IonText, useIonRouter } from '@ionic/react';
import './styles.css';
import { AppButton } from '../../components/App/Button';
import { AppTitle } from '../../components/App/Title';
import IconHeader from '../../components/App/IconHeader';
import ResponsiveContainer from '../../components/App/ResponsiveContainer';
import UploadIcon from '../../assets/upload-icon.svg';
import DeleteIcon from '../../assets/delete-icon.svg';
import { updateUserDocumentById } from '../../api/User';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { useLoading } from '../../hooks/useLoading';
import { useFormError } from '../../hooks/useFormError';
import { AppFormError } from '../../components/App/FormError';
import { convertFileToBase64, validateFieldsFilling } from '../../utils';

const UploadedDocumentInfo = ({ file, handleRemove }: { file: File; handleRemove: () => void }) => {
  return (
    <div className="file-info-container">
      <IonText className="file-info-name">{file.name}</IonText>
      <IonIcon className="file-info-icon" icon={DeleteIcon} onClick={handleRemove} />
    </div>
  );
};

type UploadFileContainerProps = {
  label: string;
  handleFileChange: (file: File | null) => void;
  file: File | null;
  error?: string;
  acceptFileExtensions?: string[];
};

const UploadFileContainer = ({
  label,
  handleFileChange,
  file,
  error,
  acceptFileExtensions,
}: UploadFileContainerProps) => {
  const inputFileRef = useRef<HTMLInputElement>(null);

  const handleChange = (resetFile = false) => {
    if (resetFile) {
      handleFileChange(null);
      return;
    }
    const files = inputFileRef.current?.files;
    if (files?.length) {
      handleFileChange(files[0]);
    }
  };

  const activateInput = () => {
    inputFileRef.current?.click();
  };

  return (
    <ResponsiveContainer
      style={{
        backgroundColor: '#060607',
        borderRadius: '1.5rem',
        margin: '1.5rem 0 0',
        padding: '1.5rem 1rem',
        maxWidth: '100%',
      }}
    >
      <IonText>{label}</IonText>
      <input
        style={{ display: 'none' }}
        type="file"
        ref={inputFileRef}
        onChange={() => handleChange()}
        placeholder="Upload"
        accept={acceptFileExtensions?.join(', ')}
        required
      />
      <AppButton
        onClick={activateInput}
        label="Upload"
        fill="outline"
        variant="secondary"
        icon={UploadIcon}
        style={{ margin: '1.5rem 0 0' }}
      />

      {file && <UploadedDocumentInfo file={file} handleRemove={() => handleChange(true)} />}
      {error && (
        <IonText
          style={{ display: 'block', textAlign: 'right', marginTop: '0.5rem' }}
          color="danger"
        >
          {error}
        </IonText>
      )}
    </ResponsiveContainer>
  );
};

const FIELDS = { frontFile: 'frontFile', backFile: 'backFile' };

const DocumentPhotosUploadScreen: React.FC = () => {
  const [frontFile, setFrontFile] = useState<File | null>(null);
  const [backFile, setBackFile] = useState<File | null>(null);
  const router = useIonRouter();
  const userAuthData = useSelector((state: RootState) => state.auth);
  const { loading, changeLoadingStatus } = useLoading();
  const { getFieldError, setFieldError, setGeneralFormError, generalFormError } = useFormError({
    initialFields: [FIELDS.frontFile, FIELDS.backFile],
  });

  const handleContinue = async () => {
    const allFilled = validateFieldsFilling(
      [
        { fieldId: FIELDS.frontFile, value: frontFile },
        { fieldId: FIELDS.backFile, value: backFile },
      ],
      setFieldError,
    );
    if (!allFilled) {
      return;
    }
    changeLoadingStatus();
    const response = await updateUserDocumentById(
      userAuthData.userId!,
      {
        document_back_image: (await convertFileToBase64(backFile!)).split(',')[1],
        document_front_image: (await convertFileToBase64(frontFile!)).split(',')[1],
      },
      userAuthData.accessToken!,
    );
    changeLoadingStatus();
    if (response === null) {
      setGeneralFormError('Erro ao atualizar documento. Tente novamente.');
      return;
    }
    console.log(response);
    console.log('Updated user document successfully!');
    router.push('/successful-registration');
  };

  return (
    <IonPage>
      <IonContent color={'dark'} fullscreen>
        <div className="document-photo-upload-flex-container">
          <div className="document-photo-upload-background-container"></div>

          <div className="document-photo-upload-action-container">
            <IconHeader />
            <div className="document-photo-upload-form-container">
              <AppTitle topTitle="" title="Faça o upload dos seus documentos" />
              <UploadFileContainer
                label="Upload da Frente do seu documento"
                handleFileChange={(file) => setFrontFile(file)}
                file={frontFile}
                error={getFieldError(FIELDS.frontFile)}
                acceptFileExtensions={['image/png', 'image/jpg', 'image/jpeg']}
              />
              <UploadFileContainer
                label="Upload do Verso do seu documento"
                handleFileChange={(file) => setBackFile(file)}
                file={backFile}
                acceptFileExtensions={['image/png', 'image/jpg', 'image/jpeg']}
                error={getFieldError(FIELDS.frontFile)}
              />
              <AppButton loading={loading} label="Continuar" onClick={handleContinue} />
              {generalFormError && <AppFormError error={generalFormError} />}
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default DocumentPhotosUploadScreen;
