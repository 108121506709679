import { apiInstance } from '../api';
import { OutputGetCitiesByStateId } from './dto';

export const getStates = async () => {
  try {
    const response = await apiInstance.get('/state');
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getCitiesByStateId = async (id: number): Promise<OutputGetCitiesByStateId | null> => {
  try {
    const response = await apiInstance.get<OutputGetCitiesByStateId>(`/state/${id}/cities`);
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};
