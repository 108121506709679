import { apiInstance } from '../api';
import { OutputGetCityDto } from './types';

export const getCities = async () => {
  try {
    const response = await apiInstance.get('/city');
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getCity = async (id: number): Promise<OutputGetCityDto | null> => {
  try {
    const response = await apiInstance.get(`/city/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};
