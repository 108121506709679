export function getHoursAndMinutes(date: Date): `${string}:${string}` {
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  return `${hours}:${minutes}`;
}

export function getFormattedDate(date: Date): string {
  return date.toLocaleDateString('pt-BR', {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
  });
}

export function getFormattedShortDate(date: Date): string {
  return date.toLocaleDateString('pt-BR', {
    day: '2-digit',
    month: '2-digit',
  });
}

export function getFormattedLongDate(date: Date): string {
  return date.toLocaleDateString('pt-BR', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
}

type Weekday = 'Domingo' | 'Segunda' | 'Terça' | 'Quarta' | 'Quinta' | 'Sexta' | 'Sábado';

export function getWeekday(date: Date): Weekday {
  const weekdays: Weekday[] = [
    'Domingo',
    'Segunda',
    'Terça',
    'Quarta',
    'Quinta',
    'Sexta',
    'Sábado',
  ];
  return weekdays[date.getDay()];
}

export function getEventTypeLabel(eventType: string): 'Show' | 'Jogo' | 'Outro' {
  switch (eventType.toUpperCase()) {
    case 'SHOW':
      return 'Show';
    case 'GAME':
      return 'Jogo';
    default:
      return 'Outro';
  }
}

export function getEventTicketStatusLabel(status: string): string {
  switch (status.toUpperCase()) {
    case 'ACTIVE':
      return 'Ativo';
    case 'INACTIVE':
      return 'Inativo';
    case 'SOLDOUT':
      return 'Esgotado';
    default:
      return 'Outro';
  }
}

export function formatCurrency(value: number): string {
  return value.toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2,
  });
}

type FieldToVerify = { value: any; fieldId: string; customMessage?: string };

export function validateFieldsFilling(
  fieldsToVerify: FieldToVerify[],
  setErrorCallback: (fieldId: string, error: string) => void,
): boolean {
  let allValid = true;
  fieldsToVerify.forEach(({ value, fieldId, customMessage }) => {
    if (!value) {
      setErrorCallback(fieldId, customMessage ?? 'Campo é obrigatório');
      allValid = false;
    }
  });
  return allValid;
}

export function isValidCpfFormat(value: string): boolean {
  const cpfRegex = /[0-9]{3}\.?[0-9]{3}\.?[0-9]{3}-?[0-9]{2}/g;
  return cpfRegex.test(value);
}

export function removeCpfMask(value: string): string {
  return value.replaceAll('.', '').replace('-', '');
}

export function convertFileToBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      resolve(reader.result!.toString());
    };
    reader.onerror = function (error) {
      reject(error);
    };
  });
}

export function isMobile(): boolean {
  return navigator.userAgent.toLowerCase().includes('mobile');
}
