// PhotoInstructionsScreen.tsx
import React, { useState } from 'react';
import {
  IonButton,
  IonCard,
  IonContent,
  IonIcon,
  IonImg,
  IonPage,
  IonText,
  useIonRouter,
} from '@ionic/react';
import './styles.css';
import { AppButton } from '../../components/App/Button';
import { AppInput } from '../../components/App/Input';
import { AppTitle } from '../../components/App/Title';
import { close, informationCircle, informationCircleOutline } from 'ionicons/icons';
import headeIcon from '../../assets/header-icon.svg';
import IconHeader from '../../components/App/IconHeader';
import { AppMaskedInput } from '../../components/App/MaskedInput';
import takePictureImg from '../../assets/take-picture-img1.png';
import Instructions1 from '../../assets/instructions/Instructions1.svg';
import Instructions2 from '../../assets/instructions/Instructions2.svg';
import Instructions3 from '../../assets/instructions/Instructions3.svg';
import Instructions4 from '../../assets/instructions/Instructions4.svg';

const Instruction = ({ icon, title, description }: any) => {
  return (
    <div className="instruction-container">
      <IonIcon icon={icon} size="large" />
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <IonText className="instruction-title">{title}</IonText>
        <IonText className="instruction-description">{description}</IonText>
      </div>
    </div>
  );
};

const Instructions = [
  {
    icon: Instructions1,
    title: 'Mantenha uma expressão neutra',
    description: 'Tente não sorrir ou se movimentar',
  },
  {
    icon: Instructions2,
    title: 'Mantenha seu rosto visível',
    description: 'Retire bonés, chapéus ou óculos',
  },
  {
    icon: Instructions3,
    title: 'Iluminação adequada',
    description: 'Evite ambientes escuros',
  },
  {
    icon: Instructions4,
    title: 'Enquadramento',
    description: 'Todo o seu rosto deve estar visivel',
  },
];

const PhotoInstructionsScreen: React.FC = () => {
  const router = useIonRouter();

  const handleContinue = () => {
    router.push('/photo-capture');
  };

  return (
    <IonPage>
      <IonContent color={'dark'} fullscreen>
        <div className="photo-flex-container">
          <div className="photo-background-container"></div>

          <div className="photo-action-container">
            <IconHeader />
            <div className="form-container">
              <IonImg src={takePictureImg} className="photo-img" />
              <AppTitle topTitle="Antes de continuar leia essas" title="Dicas importantes:" />

              <div style={{ marginTop: '3rem', marginBottom: '3rem' }}>
                {Instructions.map((instruction, index) => (
                  <Instruction key={index} {...instruction} />
                ))}
              </div>
              <AppButton label="Continuar" onClick={handleContinue} />
              <div style={{ marginLeft: '1rem', marginRight: '1rem', color: '#EBF0F9' }}>
                <IonText style={{ fontSize: '0.8rem' }}>
                  Sua imagem será armazenada com segurança e será apenas utilizada para garantir a
                  sua identidade.
                </IonText>
              </div>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default PhotoInstructionsScreen;
