// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.doc-scanner-container {
  position: relative;
  max-width: 1000px;
  width: 100%;
}

.document-rectangle-shape {
  border: 5px dashed white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  max-width: 80%;
  max-height: 60%;
  box-sizing: border-box;
  z-index: 2;
}
`, "",{"version":3,"sources":["webpack://./src/components/DocScanner/styles.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,WAAW;AACb;;AAEA;EACE,wBAAwB;EACxB,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC;EAChC,WAAW;EACX,YAAY;EACZ,cAAc;EACd,eAAe;EACf,sBAAsB;EACtB,UAAU;AACZ","sourcesContent":[".doc-scanner-container {\r\n  position: relative;\r\n  max-width: 1000px;\r\n  width: 100%;\r\n}\r\n\r\n.document-rectangle-shape {\r\n  border: 5px dashed white;\r\n  position: absolute;\r\n  top: 50%;\r\n  left: 50%;\r\n  transform: translate(-50%, -50%);\r\n  width: 100%;\r\n  height: 100%;\r\n  max-width: 80%;\r\n  max-height: 60%;\r\n  box-sizing: border-box;\r\n  z-index: 2;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
