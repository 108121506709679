// Signup flow Slice
// Contains the info to make the signup in multiple steps
// Name, email, phone, password, image(in base 64)

import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface AuthState {
  accessToken?: string;
  refreshToken?: string;
  userId?: number;
  userName?: string | null;
}

const initialState: AuthState = {};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    reset: (state) => {
      state = {};
    },
    setAccessToken: (state, action: PayloadAction<string>) => {
      state.accessToken = action.payload;
    },
    resetAccessToken: (state) => {
      state.accessToken = undefined;
    },
    setRefreshToken: (state, action: PayloadAction<string>) => {
      state.refreshToken = action.payload;
    },
    resetRefreshToken: (state) => {
      state.refreshToken = undefined;
    },
    setUserId: (state, action: PayloadAction<number>) => {
      state.userId = action.payload;
    },
    resetUserId: (state) => {
      state.userId = undefined;
    },
    setUserName: (state, action: PayloadAction<string | null>) => {
      state.userName = action.payload;
    },
    resetUserName: (state) => {
      state.userName = undefined;
    },
  },
});

export const {
  reset,
  setAccessToken,
  resetAccessToken,
  setRefreshToken,
  resetRefreshToken,
  setUserId,
  resetUserId,
  resetUserName,
  setUserName,
} = authSlice.actions;
export default authSlice.reducer;
