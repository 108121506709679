import React, { useEffect } from 'react';
import {
  IonContent,
  IonIcon,
  IonImg,
  IonItem,
  IonPage,
  IonSkeletonText,
  IonText,
  useIonRouter,
} from '@ionic/react';
import './style.css';
import { AppTitle } from '../../components/App/Title';
import { calendar, time } from 'ionicons/icons';
import QrCode from '../../assets/svg/qrcode.svg';
import { OutputGetTicketsByUserIdDto } from '../../api/User/dto';
import { getTicketsByUserId } from '../../api/User';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import {
  getEventTypeLabel,
  getFormattedDate,
  getFormattedShortDate,
  getHoursAndMinutes,
  getWeekday,
} from '../../utils';
import { useLoading } from '../../hooks/useLoading';
import { getLocations } from '../../api/location';
import { OutputGetAllLocations } from '../../api/location/dto';
import { AppHeader } from '../../components/App/Header';
import { AppFormError } from '../../components/App/FormError';

const TicketListLoadingSkeleton = () => {
  return (
    <>
      {Array.from(Array(6).keys()).map((key) => {
        return (
          <IonItem key={key} className="dark-skeleton container">
            <IonSkeletonText className="ticket-photo1 skeleton" animated={true}></IonSkeletonText>
            <div className="ticket-info1" style={{ width: '100%', marginLeft: '1rem' }}>
              <div className="ticket-header1 skeleton">
                <IonSkeletonText animated={true} style={{ width: '40%' }}></IonSkeletonText>
                <IonSkeletonText animated={true} style={{ width: '10%' }}></IonSkeletonText>
              </div>
              <IonSkeletonText style={{ width: '70%' }} animated={true}></IonSkeletonText>
              <IonSkeletonText style={{ width: '50%' }} animated={true}></IonSkeletonText>
              <div className="ticket-schedule1 skeleton">
                <div style={{ width: '30%' }}>
                  <IonSkeletonText animated={true}></IonSkeletonText>
                </div>
                <div style={{ width: '30%' }}>
                  <IonSkeletonText animated={true}></IonSkeletonText>
                </div>
              </div>
              <IonSkeletonText style={{ width: '20%' }} animated={true}></IonSkeletonText>
            </div>
          </IonItem>
        );
      })}
    </>
  );
};

type TicketProps = {
  handleTicketDetail: (id: number) => void;
  title: string;
  id: number;
  place: {
    name: string;
    gate: string;
  };
  eventType: string;
  picture: string;
  date: Date;
};

const Ticket = ({
  handleTicketDetail,
  title,
  id,
  place,
  eventType,
  date,
  picture,
}: TicketProps) => {
  return (
    <div className="ticket-container1" onClick={() => handleTicketDetail(id)}>
      <IonImg className="ticket-photo1" src={picture} />
      <div className="ticket-info1">
        <div className="ticket-header1">
          <div className="ticket-id1">
            <IonText>
              <span style={{ fontWeight: 'bold' }}>#{id}</span>
            </IonText>
          </div>
          <div className="ticket-qr">
            <IonIcon src={QrCode} />
          </div>
        </div>

        <IonText>
          <span style={{ fontWeight: 'bold' }}>{title}</span>
        </IonText>
        <IonText>
          {place.name} | Portão {place.gate}
        </IonText>

        <div className="ticket-schedule1">
          <div>
            <IonIcon src={calendar} />
            <IonText>
              {getWeekday(date)} {getFormattedShortDate(date)}
            </IonText>
          </div>
          <div>
            <IonIcon src={time} />
            <IonText>{getHoursAndMinutes(date)}</IonText>
          </div>
        </div>
        <div className="ticket-tag1">
          <IonText>
            <span style={{ fontWeight: 'bold' }}>{eventType}</span>
          </IonText>
        </div>
      </div>
    </div>
  );
};

const MyTicketsScreen: React.FC = () => {
  const router = useIonRouter();
  const userAuthData = useSelector((state: RootState) => state.auth);
  const [myTickets, setMyTickets] = React.useState<OutputGetTicketsByUserIdDto>();
  const [allLocations, setAllLocations] = React.useState<OutputGetAllLocations>();
  const [lastUpdatedAt, setLastUpdatedAt] = React.useState<Date>();
  const [error, setError] = React.useState('');
  const { loading, changeLoadingStatus } = useLoading();

  useEffect(() => {
    const fetchTickets = async () => {
      setError('');
      changeLoadingStatus();
      const ticketsData = await getTicketsByUserId(userAuthData.userId!, userAuthData.accessToken!);
      const locationsData = await getLocations(userAuthData.accessToken!);
      changeLoadingStatus();
      if (ticketsData === null || locationsData === null) {
        setError('Erro ao carregar ingressos');
        return;
      }
      setMyTickets(ticketsData);
      setAllLocations(locationsData);
      setLastUpdatedAt(new Date());
      console.log('Fetched tickets with success');
    };
    fetchTickets();
  }, []);

  const getTicketList = (tickets?: OutputGetTicketsByUserIdDto) => {
    if (loading) return <TicketListLoadingSkeleton />;
    if (error) return <AppFormError error={error} />;
    if (tickets?.length === 0) {
      return <IonText color="warning">Você não possui nenhum ingresso!</IonText>;
    }
    return tickets?.map((ticket) => {
      const ticketLocation = allLocations!.find(
        (location) => location.id === ticket.eventTicket.event.location_id,
      );
      return (
        <Ticket
          id={ticket.id}
          key={String(ticket.id)}
          place={{
            gate:
              ticketLocation?.gates.map((gate) => gate.description).join(', ') ??
              'Erro ao carregar portão',
            name: ticketLocation?.name ?? 'Erro ao carregar local',
          }}
          title={ticket.eventTicket.event.title}
          picture={ticket.eventTicket.event.picture}
          eventType={getEventTypeLabel(ticket.eventTicket.event.event_type)}
          date={new Date(ticket.eventTicket.event.date_start)}
          handleTicketDetail={handleTicketDetail}
        />
      );
    });
  };

  const handleTicketDetail = (id: number) => {
    router.push(`my-tickets-detail/${id}`, 'none');
  };

  const getLastUpdateLabelValue = (lastUpdatedAt?: Date): string => {
    if (!lastUpdatedAt) return 'Nenhuma';
    return `${getFormattedDate(lastUpdatedAt)}, ${getHoursAndMinutes(lastUpdatedAt)}h`;
  };

  return (
    <IonPage style={{ overflowY: 'auto' }}>
      <IonContent color={'dark'} fullscreen>
        <div className="bg-container">
          <AppHeader shouldReloadUserInfo={router.routeInfo.prevRouteLastPathname !== 'login'} />
          <div className="content-container2">
            <AppTitle
              topTitle="Seja bem vindo!"
              title="Aproveite seus eventos!"
              style={{ margin: 0 }}
            />

            <div className="update-text">
              <IonText style={{ color: '#999AA1' }}>
                Ultima atualização: {getLastUpdateLabelValue(lastUpdatedAt)}
              </IonText>
            </div>

            <div className="grid-container">{getTicketList(myTickets)}</div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default MyTicketsScreen;
