import React, { useState } from 'react';
import {
  IonButton,
  IonButtons,
  IonCheckbox,
  IonContent,
  IonHeader,
  IonItem,
  IonList,
  IonTitle,
  IonSearchbar,
  IonToolbar,
} from '@ionic/react';
import type { CheckboxCustomEvent } from '@ionic/react';
import type { SelectItem } from './types';

interface TypeaheadProps {
  items: SelectItem[];
  selectedItem: string;
  title?: string;
  onSelectionCancel?: () => void;
  onSelectionChange?: (item: string) => void;
}

function AppTypeahead({
  items,
  selectedItem,
  onSelectionCancel,
  onSelectionChange,
  title,
}: TypeaheadProps) {
  const [filteredItems, setFilteredItems] = useState<SelectItem[]>([...items]);
  const [workingSelectedValue, setWorkingSelectedValue] = useState<string>(selectedItem);

  const isChecked = (value: string) => {
    return workingSelectedValue === value;
  };

  const cancelChanges = () => {
    if (onSelectionCancel !== undefined) {
      onSelectionCancel();
    }
  };

  const confirmChanges = () => {
    if (onSelectionChange !== undefined) {
      onSelectionChange(workingSelectedValue);
    }
  };

  const searchbarInput = (ev: any) => {
    filterList(ev.target.value);
  };

  const filterList = (searchQuery: string | null | undefined) => {
    if (searchQuery === undefined || searchQuery === null) {
      setFilteredItems([...items]);
    } else {
      const normalizedQuery = searchQuery.toLowerCase();
      setFilteredItems(
        items.filter((item) => {
          return item.text.toLowerCase().includes(normalizedQuery);
        }),
      );
    }
  };

  const checkboxChange = (ev: CheckboxCustomEvent) => {
    const { checked, value } = ev.detail;
    if (checked) {
      setWorkingSelectedValue(value);
    }
    if (!checked && workingSelectedValue === value) {
      setWorkingSelectedValue('');
    }
  };

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton color={'dark'} onClick={cancelChanges}>
              Cancel
            </IonButton>
          </IonButtons>
          <IonTitle>{title}</IonTitle>
          <IonButtons slot="end">
            <IonButton color={'dark'} onClick={confirmChanges}>
              Done
            </IonButton>
          </IonButtons>
        </IonToolbar>
        <IonToolbar>
          <IonSearchbar autocapitalize="words" onIonInput={searchbarInput}></IonSearchbar>
        </IonToolbar>
      </IonHeader>

      <IonContent color="light" class="ion-padding">
        <IonList id="modal-list" inset={true}>
          {filteredItems.map((item) => (
            <IonItem key={item.value}>
              <IonCheckbox
                value={item.value}
                checked={isChecked(item.value)}
                onIonChange={checkboxChange}
              >
                {item.text}
              </IonCheckbox>
            </IonItem>
          ))}
        </IonList>
      </IonContent>
    </>
  );
}
export default AppTypeahead;
