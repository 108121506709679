// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-button {
  border-radius: 6px !important;
  --border-radius: 6px !important;
  font-weight: 600;
  margin-left: 0.75rem;
  margin-right: 0.75rem;
  margin-top: 1rem;
  transition: ease 0.5s;
}

.app-button-icon {
  max-width: 0.75rem;
  margin-right: 0.75rem;
}

.app-button-loading {
  pointer-events: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/App/Button/styles.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;EAC7B,+BAA+B;EAC/B,gBAAgB;EAChB,oBAAoB;EACpB,qBAAqB;EACrB,gBAAgB;EAChB,qBAAqB;AACvB;;AAEA;EACE,kBAAkB;EAClB,qBAAqB;AACvB;;AAEA;EACE,oBAAoB;AACtB","sourcesContent":[".app-button {\r\n  border-radius: 6px !important;\r\n  --border-radius: 6px !important;\r\n  font-weight: 600;\r\n  margin-left: 0.75rem;\r\n  margin-right: 0.75rem;\r\n  margin-top: 1rem;\r\n  transition: ease 0.5s;\r\n}\r\n\r\n.app-button-icon {\r\n  max-width: 0.75rem;\r\n  margin-right: 0.75rem;\r\n}\r\n\r\n.app-button-loading {\r\n  pointer-events: none;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
