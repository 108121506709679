// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-input {
  font-size: 1.25rem;
}

.app-input .native-wrapper {
  border-color: var(--app-color-support-primary);
  font-size: 1rem;
  border-width: thin;
  border-style: solid;
  border-radius: 4px;
  margin-top: 0.75rem;
  padding: 0.5rem;
  background-color: var(--app-color-on-primary);
}

.app-input-error .native-wrapper {
  border-color: var(--app-color-error) !important;
}

.input-bottom {
  height: 1rem;
  border: none !important;
}

.error-text {
  display: initial !important;
}

.app-input.app-input-long-text .label-text {
  white-space: initial;
  margin-bottom: 1rem;
  text-align: start;
}
`, "",{"version":3,"sources":["webpack://./src/components/App/Input/styles.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,8CAA8C;EAC9C,eAAe;EACf,kBAAkB;EAClB,mBAAmB;EACnB,kBAAkB;EAClB,mBAAmB;EACnB,eAAe;EACf,6CAA6C;AAC/C;;AAEA;EACE,+CAA+C;AACjD;;AAEA;EACE,YAAY;EACZ,uBAAuB;AACzB;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,oBAAoB;EACpB,mBAAmB;EACnB,iBAAiB;AACnB","sourcesContent":[".app-input {\r\n  font-size: 1.25rem;\r\n}\r\n\r\n.app-input .native-wrapper {\r\n  border-color: var(--app-color-support-primary);\r\n  font-size: 1rem;\r\n  border-width: thin;\r\n  border-style: solid;\r\n  border-radius: 4px;\r\n  margin-top: 0.75rem;\r\n  padding: 0.5rem;\r\n  background-color: var(--app-color-on-primary);\r\n}\r\n\r\n.app-input-error .native-wrapper {\r\n  border-color: var(--app-color-error) !important;\r\n}\r\n\r\n.input-bottom {\r\n  height: 1rem;\r\n  border: none !important;\r\n}\r\n\r\n.error-text {\r\n  display: initial !important;\r\n}\r\n\r\n.app-input.app-input-long-text .label-text {\r\n  white-space: initial;\r\n  margin-bottom: 1rem;\r\n  text-align: start;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
