import { apiInstance } from '../api';
import { OutputGetCountries, OutputGetStatesByCountryId } from './dto';

export const getCountries = async (): Promise<OutputGetCountries | null> => {
  try {
    const response = await apiInstance.get<OutputGetCountries>('/country');
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getStatesByCountryId = async (
  id: number,
): Promise<OutputGetStatesByCountryId | null> => {
  try {
    const response = await apiInstance.get<OutputGetStatesByCountryId>(`/country/${id}/states`);
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};
