import React, { useEffect, useRef, useState } from 'react';
import { Camera } from '@mediapipe/camera_utils';
import {
  FaceMesh,
  FACEMESH_TESSELATION,
  FACEMESH_RIGHT_EYE,
  FACEMESH_RIGHT_EYEBROW,
  FACEMESH_LEFT_EYE,
  FACEMESH_LEFT_EYEBROW,
  FACEMESH_FACE_OVAL,
  FACEMESH_LIPS,
  FACEMESH_CONTOURS,
  FACE_GEOMETRY,
  FACEMESH_LEFT_IRIS,
  FACEMESH_RIGHT_IRIS,
} from '@mediapipe/face_mesh';
import { drawConnectors } from '@mediapipe/drawing_utils';
import './styles.css';
import { AppButton } from '../App/Button';
import { IonLoading } from '@ionic/react';

const FaceMeshComponent: React.FC<{ onCapture: (image: string) => void }> = ({ onCapture }) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [image, setImage] = useState<string | null>(null);

  const handleResize = () => {
    setDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (!videoRef.current || !canvasRef.current) return;

    const faceMesh = new FaceMesh({
      locateFile: (file) => `https://cdn.jsdelivr.net/npm/@mediapipe/face_mesh/${file}`,
    });

    faceMesh.setOptions({
      maxNumFaces: 1,
      refineLandmarks: true,
      minDetectionConfidence: 0.5,
      minTrackingConfidence: 0.5,
    });

    faceMesh.onResults(onResults);

    const camera = new Camera(videoRef.current, {
      onFrame: async () => {
        await faceMesh.send({ image: videoRef.current as any });
      },
      width: 1280,
      height: 720,
    });
    camera.start();
  }, []);

  const onResults = (results: any) => {
    if (!results?.image) return;
    if (!canvasRef.current) return;
    const canvasCtx = canvasRef.current.getContext('2d');
    if (!canvasCtx || !results.image) return;

    // Update canvas size
    canvasRef.current.width = dimensions.width;
    canvasRef.current.height = dimensions.width * (results.image.height / results.image.width);

    canvasCtx.save();
    canvasCtx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    // draw it mirrored
    canvasCtx.translate(canvasRef.current.width, 0);
    canvasCtx.scale(-1, 1);
    canvasCtx.drawImage(results.image, 0, 0, canvasRef.current.width, canvasRef.current.height);

    // Draw a circle of 25% of radius in the middle of the canvas
    // stroke should be white with 2px width

    // if (results.multiFaceLandmarks && results.multiFaceLandmarks?.[0]) {
    //   for (const landmarks of results.multiFaceLandmarks as any[]) {
    //     drawConnectors(canvasCtx, landmarks, FACEMESH_TESSELATION, {
    //       color: '#C0C0C070',
    //       lineWidth: 0.4,
    //     });
    //     // drawConnectors(canvasCtx, landmarks, FACEMESH_RIGHT_EYE, { color: '#FFF' });
    //     // drawConnectors(canvasCtx, landmarks, FACEMESH_RIGHT_EYEBROW, { color: '#FFF' });
    //     // drawConnectors(canvasCtx, landmarks, FACEMESH_LEFT_EYE, { color: '#fff' });
    //     // drawConnectors(canvasCtx, landmarks, FACEMESH_LEFT_EYEBROW, { color: '#fff' });
    //     // drawConnectors(canvasCtx, landmarks, FACEMESH_FACE_OVAL, { color: '#E0E0E0' });
    //     // drawConnectors(canvasCtx, landmarks, FACEMESH_LEFT_IRIS, { color: '#E0E0E0' });
    //     // drawConnectors(canvasCtx, landmarks, FACEMESH_RIGHT_IRIS, { color: '#E0E0E0' });

    //     // drawConnectors(canvasCtx, landmarks, FACEMESH_LIPS, { color: '#E0E0E0' });
    //   }

    // IF LOOKING FORWARD AND FACE INSIDE THE CIRCLE TURN THE CIRCLE GREEN
    // ELSE TURN IT WHITE
    // const nose = results.multiFaceLandmarks[0][5];
    // const leftEye = results.multiFaceLandmarks[0][33];
    // const rightEye = results.multiFaceLandmarks[0][263];
    // const mouth = results.multiFaceLandmarks[0][57];
    // const face = results.multiFaceLandmarks[0];
    // const faceWidth = Math.abs(face[10].x - face[234].x);
    // const faceHeight = Math.abs(face[10].y - face[152].y);
    // const faceArea = faceWidth * faceHeight;
    // const faceCenter = { x: face[10].x + faceWidth / 2, y: face[10].y + faceHeight / 2 };

    // const faceCenterX = faceCenter.x * canvasRef.current.width;
    // const faceCenterY = faceCenter.y * canvasRef.current.height;

    // const faceCenterDistance = Math.sqrt(
    //   Math.pow(faceCenterX - canvasRef.current.width / 2, 2) +
    //     Math.pow(faceCenterY - canvasRef.current.height / 2, 2),
    // );

    // let circleRadius;
    // if (canvasRef.current.width > canvasRef.current.height) {
    //   circleRadius = canvasRef.current.height * 0.4;
    // } else {
    //   circleRadius = canvasRef.current.width * 0.4;
    // }

    // const top = face[10].y * canvasRef.current.height;
    // const bottom = face[152].y * canvasRef.current.height;

    // // to make sure entire face is inside the circle
    // const maxCircleTop = canvasRef.current.height / 2 - circleRadius;
    // const minCircleBottom = canvasRef.current.height / 2 + circleRadius;

    // console.log(faceCenterDistance, faceArea);
    // write the faceCenterDistance and faceArea to the screen
    // canvasCtx.font = '30px Arial';
    // canvasCtx.fillStyle = 'white';
    // canvasCtx.fillText(`Distance: ${faceCenterDistance.toFixed(2)}`, 10, 50);
    // canvasCtx.fillText(`Area: ${faceArea.toFixed(2)}`, 10, 100);

    // if (
    //   faceCenterDistance < circleRadius &&
    //   faceArea > 0.095 &&
    //   top > maxCircleTop &&
    //   bottom < minCircleBottom
    // ) {
    //   canvasCtx.beginPath();
    //   canvasCtx.arc(
    //     canvasRef.current.width / 2,
    //     canvasRef.current.height / 2,
    //     circleRadius,
    //     0,
    //     2 * Math.PI,
    //   );
    //   canvasCtx.strokeStyle = 'green';
    //   canvasCtx.lineWidth = 7;
    //   canvasCtx.stroke();
    // } else {
    //   canvasCtx.beginPath();
    //   canvasCtx.arc(
    //     canvasRef.current.width / 2,
    //     canvasRef.current.height / 2,
    //     circleRadius,
    //     0,
    //     2 * Math.PI,
    //   );
    //   canvasCtx.strokeStyle = 'white';
    //   canvasCtx.lineWidth = 7;
    //   canvasCtx.stroke();
    // }
    // }
    canvasCtx.restore();
  };

  const getImageOnClick = () => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const image = new Image();
    image.src = canvas.toDataURL('image/jpg');

    image.onload = () => {
      const link = document.createElement('a');
      link.href = image.src;
      // transform into base64
      setImage(image.src);
      onCapture(image.src);
    };
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', maxWidth: '800px' }}>
      <video ref={videoRef} className="input-video" style={{ display: 'none' }} playsInline></video>
      <canvas ref={canvasRef} className="output-canvas"></canvas>
      <div>
        <AppButton
          label="Capturar"
          onClick={getImageOnClick}
          style={{ maxWidth: '400px', margin: '2rem auto 0 auto' }}
        />
      </div>
    </div>
  );
};

export default FaceMeshComponent;
