{
  /* <IonText>
							Reveja seus
						</IonText>
						<IonText>
							<h1>Dados Pessoais</h1>
						</IonText> */
}

import React from 'react';
import { IonText } from '@ionic/react';

export const AppTitle = ({
  topTitle,
  title,
  invert = false,
  style,
}: {
  topTitle: string;
  title: string;
  invert?: boolean;
  style?: React.CSSProperties;
}) => {
  if (invert) {
    return (
      <div
        style={{
          marginLeft: '1rem',
          marginRight: '1rem',
          ...style,
        }}
      >
        <IonText>
          <h1
            style={{
              marginTop: 0,
            }}
          >
            {topTitle}
          </h1>
        </IonText>
        <IonText>{title}</IonText>
      </div>
    );
  }

  return (
    <div
      style={{
        marginLeft: '1rem',
        marginRight: '1rem',
        ...style,
      }}
    >
      <IonText>{topTitle}</IonText>
      <IonText>
        <h1
          style={{
            marginTop: 0,
          }}
        >
          {title}
        </h1>
      </IonText>
    </div>
  );
};
