import React, { useEffect, useState } from 'react';
import { IonContent, IonIcon, IonPage, IonText, useIonRouter } from '@ionic/react';
import './style.css';
import { chevronBack, personCircleOutline } from 'ionicons/icons';

import person from '../../assets/svg/profile.svg';
import facial from '../../assets/svg/facial-recognition.svg';
import logout from '../../assets/svg/logout.svg';
import { getUserById } from '../../api/User';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../redux/store';

const UserOptionsScreen: React.FC = () => {
  const router = useIonRouter();
  const authState = useSelector((state: RootState) => state.auth);
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');

  useEffect(() => {
    const fetchUserData = async () => {
      const userResponse = await getUserById(authState.userId!, authState.accessToken!);
      if (userResponse === null) {
        return;
      }
      setName(`${userResponse.first_name} ${userResponse.last_name}`);
      setEmail(`${userResponse.first_name} ${userResponse.last_name}`);
    };
    fetchUserData();
  }, []);

  const handleBack = () => {
    router.push('/my-tickets', 'none');
  };

  const handleReviewMyData = () => {
    router.push('/personal', 'none');
  };

  const handleFacial = () => {
    router.push('/photo-option', 'none');
  };

  const handleLogout = () => {
    router.push('/login', 'none');
  };

  return (
    <IonPage>
      <IonContent color={'dark'} fullscreen>
        <div className="options-flex-container">
          <div className="options-background-container"></div>

          <div className="options-action-container">
            <div className="back-button">
              <IonIcon src={chevronBack} color="white" size="large" onClick={handleBack} />
              <IonText>Voltar para meus pedidos</IonText>
            </div>
            <div className="form-container">
              <div className="profile-container">
                <IonIcon src={personCircleOutline} style={{ fontSize: '48px' }} />
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <IonText>
                    <span style={{ fontWeight: 'bold' }}>{name}</span>
                  </IonText>
                  <IonText>{email}</IonText>
                </div>
              </div>
              <hr />
              <div className="options">
                <div className="optionsItem" onClick={handleReviewMyData}>
                  <IonIcon src={person} style={{ fontSize: '24px' }} />
                  <IonText>Revisar dados pessoais</IonText>
                </div>
                <div className="optionsItem" onClick={handleFacial}>
                  <IonIcon src={facial} style={{ fontSize: '24px' }} />
                  <IonText>Realize seu reconhecimento facial</IonText>
                </div>
                <div className="optionsItem" onClick={handleLogout}>
                  <IonIcon src={logout} style={{ fontSize: '24px' }} />
                  <IonText>Logout</IonText>
                </div>
              </div>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default UserOptionsScreen;
