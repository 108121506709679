// Export a Ionic Input component styled with the Ionic CSS utilities.
// smth similar to the Button component: <IonInput label="Outline input" labelPlacement="floating" fill="outline" placeholder="Enter text" />

import React, { MouseEvent, MouseEventHandler } from 'react';
import { InputChangeEventDetail, IonInput } from '@ionic/react';
import './styles.css';

type AppInputProps = {
  label: string;
  placeholder: string;
  required: boolean;
  type?: any;
  onChange: (event: CustomEvent<InputChangeEventDetail>) => void;
  onClick?: MouseEventHandler<HTMLIonInputElement> | undefined;
  value: string;
  id?: string;
  className?: string;
  readonly?: boolean;
  disabled?: boolean;
  error?: string;
};

export const AppInput = ({
  label,
  placeholder,
  required = false,
  onChange,
  value,
  type,
  id,
  readonly = false,
  onClick,
  disabled,
  error,
  className,
  ...props
}: AppInputProps) => {
  return (
    <IonInput
      label={label}
      labelPlacement="stacked"
      fill="solid"
      placeholder={placeholder}
      required={required}
      onIonInput={onChange}
      value={value}
      type={type}
      onClick={onClick}
      errorText={error}
      className={`app-input ${error ? 'app-input-error' : ''} ${className ?? ''}`}
      readonly={readonly}
      disabled={disabled}
      {...props}
    />
  );
};
