import React, { useEffect, useState } from 'react';
import { IonContent, IonImg, IonPage, useIonRouter } from '@ionic/react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../redux/store';
import { setDocumentBackImage, setDocumentFrontImage } from '../../redux/signup';
import DocumentScanner from '../../components/DocScanner';
import './styles.css';
import { AppButton } from '../../components/App/Button';
import { updateUserDocumentById } from '../../api/User';
import { useLoading } from '../../hooks/useLoading';
import { useFormError } from '../../hooks/useFormError';
import { AppFormError } from '../../components/App/FormError';

type PhotoVerifyStepProps = {
  image: string;
  error?: string;
  handleContinue: () => void;
  handleRetake: () => void;
  loading?: boolean;
};

const PhotoVerifyStep: React.FC<PhotoVerifyStepProps> = ({
  image,
  handleContinue,
  handleRetake,
  loading,
  error,
}) => {
  return (
    <IonPage>
      <IonContent color={'dark'} fullscreen>
        <h1
          style={{
            backgroundColor: 'var(--app-color-on-secondary)',
            color: 'white',
            textAlign: 'center',
            margin: 0,
            padding: '10% 0 2rem',
          }}
        >
          A foto ficou boa?
        </h1>
        <div
          style={{
            width: '100%',
            height: '100%',
            backgroundColor: 'var(--app-color-on-secondary)',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'start',
            alignItems: 'center',
            padding: '1rem',
          }}
        >
          {image && <IonImg src={image} style={{ maxWidth: '800px' }} />}
          {error && <AppFormError error={error} />}
          <AppButton
            style={{ width: '100%', maxWidth: '400px' }}
            label="Continuar"
            onClick={handleContinue}
            loading={loading}
          />
          <AppButton
            style={{ width: '100%', maxWidth: '400px' }}
            label="Refazer"
            variant="secondary"
            onClick={handleRetake}
          />
        </div>
      </IonContent>
    </IonPage>
  );
};

const DocumentCaptureScreen: React.FC = () => {
  const router = useIonRouter();
  const userAuthData = useSelector((state: RootState) => state.auth);
  const signupData = useSelector((state: RootState) => state.signup);
  const dispatch = useDispatch();
  const [shouldCaptureBackImage, setShouldCaptureBackImage] = React.useState(false);
  const [shouldVerifyImage, setShouldVerifyImage] = useState(false);
  const { changeLoadingStatus, loading } = useLoading();
  const [error, setError] = useState('');

  const getContent = () => {
    if (shouldVerifyImage && signupData.documentFrontImage && !shouldCaptureBackImage) {
      return (
        <PhotoVerifyStep
          handleContinue={() => {
            setShouldVerifyImage(false);
            setShouldCaptureBackImage(true);
          }}
          handleRetake={() => {
            setShouldVerifyImage(false);
            setShouldCaptureBackImage(false);
          }}
          image={signupData.documentFrontImage}
        />
      );
    }
    if (shouldVerifyImage && signupData.documentBackImage) {
      return (
        <>
          <PhotoVerifyStep
            handleContinue={async () => {
              setError('');
              changeLoadingStatus();
              const result = await updateUserDocumentById(
                userAuthData.userId!,
                {
                  document_front_image: signupData.documentFrontImage!,
                  document_back_image: signupData.documentBackImage!,
                },
                userAuthData.accessToken!,
              );
              changeLoadingStatus();
              if (result === null) {
                setError('Ocorreu um erro ao enviar as imagens do documento. Tente novamente!');
                return;
              }
              router.push('/successful-registration');
            }}
            handleRetake={() => {
              setShouldVerifyImage(false);
            }}
            image={signupData.documentBackImage}
            loading={loading}
            error={error}
          />
        </>
      );
    }
    return (
      <div className="document-page-container">
        <DocumentScanner
          label={
            shouldCaptureBackImage
              ? 'Capture o verso do seu documento'
              : 'Capture a frente do seu documento'
          }
          onCapture={(img) => {
            if (shouldCaptureBackImage) {
              dispatch(setDocumentBackImage(img));
              setShouldVerifyImage(true);
              return;
            }
            dispatch(setDocumentFrontImage(img));
            setShouldVerifyImage(true);
          }}
        />
      </div>
    );
  };

  return (
    <IonPage>
      <IonContent color={'light'} fullscreen>
        {getContent()}
      </IonContent>
    </IonPage>
  );
};

export default DocumentCaptureScreen;
