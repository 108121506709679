import { useEffect, useState } from 'react';

type Field = {
  fieldId: string;
  error?: string;
};

type UseFormErrorConfig = {
  initialFields: string[];
};

export const useFormError = ({ initialFields }: UseFormErrorConfig) => {
  const [fields, setFields] = useState<Field[]>(initialFields.map((fieldId) => ({ fieldId })));
  const [anyFieldsError, setAnyFieldsError] = useState(false);
  const [generalFormError, setGeneralFormError] = useState<string>();

  useEffect(() => {
    if (fields.some((f) => f.error)) {
      setAnyFieldsError(true);
      return;
    }
    setAnyFieldsError(false);
  }, [fields]);

  const getFieldError = (fieldId: string) => {
    const field = fields.find((f) => f.fieldId === fieldId);
    return field?.error;
  };

  const setFieldError = (fieldId: string, error: string) => {
    const field = fields.find((f) => f.fieldId === fieldId);
    if (field) {
      setFields((prevFields) => {
        const notModifiedFields = prevFields.filter((f) => f.fieldId !== fieldId);
        return [...notModifiedFields, { fieldId, error }];
      });
      field.error = error;
    }
  };

  const clearFieldsErrors = (fieldId?: string) => {
    if (!fieldId) {
      setFields((prevFields) => prevFields.map((f) => ({ ...f, error: undefined })));
      return;
    }
    setFields((prevFields) => {
      const notModifiedFields = prevFields.filter((f) => f.fieldId !== fieldId);
      const field = prevFields.find((f) => f.fieldId === fieldId)!;
      return [...notModifiedFields, { ...field, error: undefined }];
    });
  };

  const clearGeneralFormError = () => {
    setGeneralFormError(undefined);
  };

  return {
    setFieldError,
    getFieldError,
    fields,
    anyFieldsError,
    clearFieldsErrors,
    setGeneralFormError,
    clearGeneralFormError,
    generalFormError,
  };
};
