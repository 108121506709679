import { useState } from 'react';

export const useLoading = () => {
  const [loading, setLoading] = useState(false);

  const changeLoadingStatus = () => {
    setLoading((prevLoading) => !prevLoading);
  };
  return { loading, changeLoadingStatus };
};
