import { AxiosError } from 'axios';
import { apiInstance } from '../api';

type OutputLoginDto = { access_token: string; refresh_token: string; user_id: number };

export const login = async (email: string, password: string): Promise<OutputLoginDto | null> => {
  try {
    const response = await apiInstance.post<OutputLoginDto>('/auth/login', {
      email,
      password,
      strategy: 'OAUTH',
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const requestPasswordReset = async (email: string, callbackUrl: string) => {
  try {
    const response = await apiInstance.post('/auth/user/request/password', {
      email,
      callbackUrl,
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const resetPassword = async (token: string, password: string) => {
  try {
    const input = { token, password, type: 'PASSWORD_RESET' };
    const response = await apiInstance.post('/auth/user/reset/password', input);
    return response.data;
  } catch (error) {
    const axiosError = error as AxiosError;
    if (axiosError.response?.status === 400) {
      return axiosError.response?.data;
    }
    console.error(error);
    return null;
  }
};

export const logout = async (accessToken: string) => {
  try {
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };
    const response = await apiInstance.post('/auth/logout', {}, { headers });
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};
