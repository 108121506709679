// Signup flow Slice
// Contains the info to make the signup in multiple steps
// Name, email, phone, password, image(in base 64)

import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface SignupState {
  name?: string;
  image?: string;
  documentFrontImage?: string;
  documentBackImage?: string;
}

const initialState: SignupState = {};

export const signupSlice = createSlice({
  name: 'signup',
  initialState,
  reducers: {
    reset: (state) => {
      state = {};
    },
    setImage: (state, action: PayloadAction<string>) => {
      state.image = action.payload;
    },
    resetImage: (state) => {
      state.image = undefined;
    },
    setDocumentFrontImage: (state, action: PayloadAction<string>) => {
      state.documentFrontImage = action.payload;
    },
    resetDocumentFrontImage: (state) => {
      state.documentFrontImage = undefined;
    },
    setDocumentBackImage: (state, action: PayloadAction<string>) => {
      state.documentBackImage = action.payload;
    },
    resetDocumentBackImage: (state) => {
      state.documentBackImage = undefined;
    },
  },
});

export const {
  reset,
  setImage,
  resetImage,
  setDocumentFrontImage,
  resetDocumentFrontImage,
  setDocumentBackImage,
  resetDocumentBackImage,
} = signupSlice.actions;
export default signupSlice.reducer;
