import React, { useEffect, useState } from 'react';
import {
  IonContent,
  IonIcon,
  IonImg,
  IonPage,
  IonSkeletonText,
  IonText,
  useIonRouter,
} from '@ionic/react';
import './style.css';
import { calendar, chevronBack, time } from 'ionicons/icons';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import {
  formatCurrency,
  getEventTicketStatusLabel,
  getEventTypeLabel,
  getFormattedDate,
  getFormattedLongDate,
  getFormattedShortDate,
  getHoursAndMinutes,
  getWeekday,
} from '../../utils';
import { RouteComponentProps } from 'react-router';
import { getTicketById } from '../../api/ticket';
import { OutputGetTicketById } from '../../api/ticket/dto';
import { OutputGetLocationById } from '../../api/location/dto';
import { getLocationById } from '../../api/location';
import { useLoading } from '../../hooks/useLoading';
import { AppHeader } from '../../components/App/Header';
import QRCode from 'qrcode';

type TicketDetailProps = {
  id: number;
  title: string;
  place: {
    name: string;
    gate: string;
  };
  date: Date;
  eventType: string;
  status: string;
  updatedAt: Date;
  purchasedAt: Date;
  qrCodeValue?: string;
  price: number;
  picture: string;
  hasFacial: boolean;
};

const TicketDetail = ({
  id,
  date,
  eventType,
  place,
  purchasedAt,
  qrCodeValue,
  status,
  title,
  updatedAt,
  price,
  hasFacial,
  picture,
}: TicketDetailProps) => {
  const [qrCode, setQrCode] = useState<string | null>(null);

  useEffect(() => {
    if (!qrCodeValue) return;
    QRCode.toCanvas(qrCodeValue, {
      width: 240,
      margin: 1,
    }).then((canvas) => {
      setQrCode(canvas.toDataURL());
    });
  }, []);

  return (
    <>
      <div className="whole-ticket-container">
        <div className="ticket-wrapper">
          <div className="ticket-container">
            <IonImg src={picture} className="ticket-photo"></IonImg>
            <div className="ticket-info">
              <div className="ticket-id">
                <IonText>#{id}</IonText>
              </div>
              <IonText>
                <span style={{ fontWeight: 'bold' }}>{title}</span>
              </IonText>
              <IonText>
                {place.name} | Portão {place.gate}
              </IonText>

              <div className="ticket-schedule">
                <div>
                  <IonIcon src={calendar} />
                  <IonText>
                    {getWeekday(date)} {getFormattedShortDate(date)}
                  </IonText>
                </div>
                <div>
                  <IonIcon src={time} />
                  <IonText>{getHoursAndMinutes(date)}</IonText>
                </div>
              </div>
              <div className="ticket-tag">
                <IonText>{getEventTypeLabel(eventType)}</IonText>
              </div>
            </div>
          </div>
          <div className="divider"></div>
          <div className="ticket-container2">
            <div className="ticket-update">
              <div className="ticket-update-1">
                <IonText>
                  Status:
                  <IonText>
                    <span style={{ color: '#6D99FB' }}> {getEventTicketStatusLabel(status)}</span>
                  </IonText>
                </IonText>
              </div>
              <div className="ticket-update-2">
                <IonText style={{ color: '#808FA9' }}>
                  Atualização {getFormattedDate(updatedAt)}
                </IonText>
              </div>
            </div>
            <div className="ticket-update">
              <div>
                <IonText>Compra: {getFormattedLongDate(purchasedAt)}</IonText>
              </div>
              <div>
                <IonText>Valor: {formatCurrency(price)}</IonText>
              </div>
            </div>
            <div className="ticket-update">
              <div>
                <IonText>Tipo de Entrada:</IonText>
                <hr />
                <IonText>{hasFacial ? 'Reconhecimento Facial' : 'QR Code'}</IonText>
              </div>
            </div>
          </div>
        </div>

        <div className="ticket-container3">
          <div className="ticket-qr-container">{qrCode && <IonImg src={qrCode} />}</div>
        </div>
      </div>
    </>
  );
};

const TicketDetailLoadingSkeleton = () => {
  return (
    <div className="whole-ticket-container">
      <div className="ticket-wrapper skeleton">
        <div className="ticket-container skeleton">
          <IonSkeletonText animated={true} className="ticket-photo"></IonSkeletonText>
          <div className="ticket-info skeleton">
            <div className="ticket-id" style={{ width: '40%' }}>
              <IonSkeletonText animated={true}></IonSkeletonText>
            </div>
            <IonSkeletonText
              animated={true}
              style={{ width: '80%' }}
              className="light-skeleton"
            ></IonSkeletonText>
            <IonSkeletonText
              animated={true}
              style={{ width: '50%' }}
              className="light-skeleton"
            ></IonSkeletonText>

            <div className="ticket-schedule" style={{ width: '100%' }}>
              <div style={{ width: '50%' }} className="light-skeleton">
                <IonSkeletonText animated={true}></IonSkeletonText>
              </div>
              <div style={{ width: '30%' }} className="light-skeleton">
                <IonSkeletonText animated={true}></IonSkeletonText>
              </div>
            </div>
            <IonSkeletonText
              className="ticket-tag"
              animated={true}
              style={{ width: '30%' }}
            ></IonSkeletonText>
          </div>
        </div>
        <div className="divider"></div>
        <div className="ticket-container2" style={{ width: '100%' }}>
          <div className="ticket-update" style={{ width: '100%' }}>
            <IonSkeletonText
              animated={true}
              style={{ width: '30%' }}
              className="light-skeleton"
            ></IonSkeletonText>
            <IonSkeletonText
              animated={true}
              style={{ width: '30%' }}
              className="light-skeleton"
            ></IonSkeletonText>
          </div>
          <div className="ticket-update" style={{ width: '100%' }}>
            <IonSkeletonText
              animated={true}
              style={{ width: '30%' }}
              className="light-skeleton"
            ></IonSkeletonText>
            <IonSkeletonText
              animated={true}
              style={{ width: '30%' }}
              className="light-skeleton"
            ></IonSkeletonText>
          </div>
          <div className="ticket-update" style={{ width: '100%' }}>
            <div style={{ width: '100%' }}>
              <IonSkeletonText
                animated={true}
                style={{ width: '50%' }}
                className="light-skeleton"
              ></IonSkeletonText>
              <hr />
              <IonSkeletonText
                animated={true}
                style={{ width: '30%' }}
                className="light-skeleton"
              ></IonSkeletonText>
            </div>
          </div>
        </div>
      </div>
      <div className="ticket-container3">
        <div className="ticket-qr-container">
          <IonSkeletonText animated={true} className="light-skeleton"></IonSkeletonText>
        </div>
      </div>
    </div>
  );
};

const MyTicketsDetailScreen: React.FC<
  RouteComponentProps<{
    id: string;
  }>
> = ({ match }) => {
  const router = useIonRouter();
  const userAuthData = useSelector((state: RootState) => state.auth);
  const [ticket, setTicket] = useState<OutputGetTicketById>();
  const [ticketLocation, setTicketLocation] = useState<OutputGetLocationById>();
  const { loading, changeLoadingStatus } = useLoading();

  useEffect(() => {
    const fetchTicketDetail = async () => {
      changeLoadingStatus();
      const ticketDetail = await getTicketById(+match.params.id, userAuthData.accessToken!);
      if (ticketDetail === null) {
        console.log('Error fetching ticket detail.');
        changeLoadingStatus();
        return;
      }
      const location = await getLocationById(
        ticketDetail.eventTicket.event.location_id!,
        userAuthData.accessToken!,
      );
      changeLoadingStatus();
      if (location === null) {
        console.log('Error fetching location.');
        return;
      }
      setTicketLocation(location);
      setTicket(ticketDetail);
    };
    fetchTicketDetail();
  }, []);

  const handleBack = () => {
    router.push('/my-tickets', 'back');
  };

  const getTicketDetailContent = () => {
    if (loading || !ticket || !ticketLocation) {
      return <TicketDetailLoadingSkeleton />;
    }
    return (
      <TicketDetail
        date={new Date(ticket.eventTicket.event.date_start)}
        eventType={ticket.eventTicket.event.event_type}
        id={ticket.id}
        place={{
          gate: ticketLocation.gates.map((gate) => gate.description).join(', '),
          name: ticketLocation.name,
        }}
        price={ticket.eventTicket.price}
        purchasedAt={new Date(ticket.created_at)}
        qrCodeValue={ticket.code}
        status={ticket.eventTicket.status}
        title={ticket.eventTicket.event.title}
        updatedAt={new Date(ticket.updated_at)}
        picture={ticket.eventTicket.event.picture}
        hasFacial={ticket.hasFacial}
      />
    );
  };

  return (
    <IonPage>
      <IonContent color={'dark'} fullscreen>
        <div className="background-container">
          <AppHeader />
          <div className="back-button2">
            <IonIcon src={chevronBack} color="white" size="large" onClick={handleBack} />
            <IonText>Voltar</IonText>
          </div>
          <div className="content-container">
            <div className="helper-text">Confira mais informações do seu ingresso</div>
            {getTicketDetailContent()}
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default MyTicketsDetailScreen;
