import React, { useState } from 'react';
import { IonContent, IonIcon, IonImg, IonPage, IonText, useIonRouter } from '@ionic/react';
import './styles.css';
import { AppButton } from '../../components/App/Button';
import { AppTitle } from '../../components/App/Title';
import IconHeader from '../../components/App/IconHeader';
import documentIcon from '../../assets/document-icon.png';
import Instructions1 from '../../assets/instructions/Instructions1.svg';
import Instructions3 from '../../assets/instructions/Instructions3.svg';
import Instructions4 from '../../assets/instructions/Instructions4.svg';
import { isMobile } from '../../utils';

const Instruction = ({ icon, title, description }: any) => {
  return (
    <div className="instruction-container">
      <IonIcon icon={icon} size="large" />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <IonText className="instruction-title">{title}</IonText>
        <IonText className="instruction-description">{description}</IonText>
      </div>
    </div>
  );
};

const Instructions = [
  {
    icon: Instructions1,
    title: 'CNH, RG ou Passaporte',
    description: 'Você pode cadastrar qualquer um dos três documentos',
  },
  {
    icon: Instructions3,
    title: 'Iluminação adequada',
    description: 'Evite ambientes escuros',
  },
  {
    icon: Instructions4,
    title: 'Enquadramento',
    description: 'Garanta que o documento está centralizado',
  },
];

const DocumentPhotosInstructionsScreen: React.FC = () => {
  const router = useIonRouter();

  const handleContinue = () => {
    if (isMobile()) {
      router.push('/document-capture');
      return;
    }
    router.push('/document-photos-upload');
  };

  return (
    <IonPage>
      <IonContent color={'dark'} fullscreen>
        <div className="photo-flex-container">
          <div className="photo-background-container"></div>

          <div className="photo-action-container">
            <IconHeader />
            <div className="form-container">
              <IonImg src={documentIcon} className="photo-img" />
              <AppTitle
                topTitle="Agora vamos cadastrar o seu documento"
                title="Dicas importantes:"
              />

              <div style={{ marginTop: '3rem', marginBottom: '3rem' }}>
                {Instructions.map((instruction, index) => (
                  <Instruction key={index} {...instruction} />
                ))}
              </div>
              <AppButton label="Continuar" onClick={handleContinue} />
              <div
                style={{
                  marginLeft: '1rem',
                  marginRight: '1rem',
                  color: '#EBF0F9',
                }}
              >
                <IonText style={{ fontSize: '0.8rem' }}>
                  Sua imagem será armazenada com segurança e será apenas utilizada para garantir a
                  sua identidade.
                </IonText>
              </div>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default DocumentPhotosInstructionsScreen;
