// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.camera-container {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
  }
  
  
  
  .capture-button {
	position: absolute;
	bottom: 20px; /* Adjust as needed */
	padding: 10px 20px;
	font-size: 18px;
	background-color: white;
	border: none;
	border-radius: 20px;
  }`, "",{"version":3,"sources":["webpack://./src/components/FaceCapture/styles.css"],"names":[],"mappings":"AAAA;CACC,kBAAkB;CAClB,aAAa;CACb,uBAAuB;CACvB,mBAAmB;EAClB;;;;EAIA;CACD,kBAAkB;CAClB,YAAY,EAAE,qBAAqB;CACnC,kBAAkB;CAClB,eAAe;CACf,uBAAuB;CACvB,YAAY;CACZ,mBAAmB;EAClB","sourcesContent":[".camera-container {\r\n\tposition: relative;\r\n\tdisplay: flex;\r\n\tjustify-content: center;\r\n\talign-items: center;\r\n  }\r\n  \r\n  \r\n  \r\n  .capture-button {\r\n\tposition: absolute;\r\n\tbottom: 20px; /* Adjust as needed */\r\n\tpadding: 10px 20px;\r\n\tfont-size: 18px;\r\n\tbackground-color: white;\r\n\tborder: none;\r\n\tborder-radius: 20px;\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
