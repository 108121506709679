import React, { useState } from 'react';
import { IonContent, IonPage, useIonRouter } from '@ionic/react';
import './style.css';
import { AppTitle } from '../../components/App/Title';
import IconHeader from '../../components/App/IconHeader';
import { AppInput } from '../../components/App/Input';
import { AppButton } from '../../components/App/Button';
import { useFormError } from '../../hooks/useFormError';
import { validateFieldsFilling } from '../../utils';
import { resetPassword } from '../../api/auth';
import { useLocation, useParams } from 'react-router';
import { AppFormError } from '../../components/App/FormError';
import { useQuery } from '../../hooks/useQuery';
import { useLoading } from '../../hooks/useLoading';

const FIELDS = { password: 'password', confirmPassword: 'confirmPassword' };

const ResetPasswordScreen: React.FC = () => {
  const query = useQuery();
  const router = useIonRouter();
  const {
    getFieldError,
    setFieldError,
    generalFormError,
    setGeneralFormError,
    clearGeneralFormError,
    clearFieldsErrors,
  } = useFormError({ initialFields: [FIELDS.password, FIELDS.confirmPassword] });
  const { changeLoadingStatus, loading } = useLoading();

  const handleResetPassword = async () => {
    clearFieldsErrors();
    clearGeneralFormError();
    const allFilled = validateFieldsFilling(
      [
        { fieldId: FIELDS.confirmPassword, value: confirm },
        { fieldId: FIELDS.password, value: password },
      ],
      setFieldError,
    );
    if (!allFilled) {
      return;
    }
    if (password !== confirm) {
      setFieldError(FIELDS.password, ' ');
      setFieldError(FIELDS.confirmPassword, 'Senhas não coincidem.');
      return;
    }
    changeLoadingStatus();
    const response = await resetPassword(query.get('token')!, password);
    changeLoadingStatus();
    if (response === null) {
      setGeneralFormError('Erro ao mudar a senha. Tente novamente.');
      return;
    }
    if (response.statusCode === 400) {
      setGeneralFormError(`Erro ao resetar senha: ${response.message}`);
      return;
    }
    router.push('/login', 'none');
  };

  const [password, setPassword] = useState<string>('');
  const [confirm, setConfirmPassword] = useState<string>('');

  return (
    <IonPage>
      <IonContent color={'dark'} fullscreen>
        <div className="reset-flex-container">
          <div className="reset-background-container"></div>

          <div className="reset-action-container">
            <IconHeader />
            <div className="form-container">
              <AppTitle topTitle="Informe seus dados para" title="Recuperar a sua senha" />
              <div className="reset-button-container">
                <AppInput
                  required
                  label="Nova senha"
                  placeholder="Digite sua senha"
                  type="password"
                  error={getFieldError(FIELDS.password)}
                  onChange={(e) => setPassword(e.detail.value ?? '')}
                  value={password}
                />
                <AppInput
                  required
                  label="Confirmar sua nova senha"
                  placeholder="Digite sua senha novamente"
                  type="password"
                  error={getFieldError(FIELDS.confirmPassword)}
                  onChange={(e) => setConfirmPassword(e.detail.value ?? '')}
                  value={confirm}
                />
              </div>
              <AppButton label="Confirmar" onClick={handleResetPassword} loading={loading} />
              {generalFormError && <AppFormError error={generalFormError} />}
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default ResetPasswordScreen;
