import { AxiosError } from 'axios';
import { apiInstance } from '../api';
import {
  InputCreateUserDto,
  InputUpdateUserByIdDto,
  InputUpdateUserDocumentByIdDto,
  InputUpdateUserImageByIdDto,
  OutputGetTicketsByUserIdDto,
  OutputGetUserByIdDto,
} from './dto';

export const createUser = async (input: InputCreateUserDto) => {
  try {
    const response = await apiInstance.post('/user/login', input);
    return response.data;
  } catch (error) {
    console.error(error);
    const axiosError = error as AxiosError;
    if (axiosError.response?.status === 400) {
      return axiosError.response?.data;
    }
    return null;
  }
};

export const getUsers = async (accessToken: string) => {
  try {
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };
    const response = await apiInstance.get('/user', { headers });
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getUserById = async (
  id: number,
  accessToken: string,
): Promise<OutputGetUserByIdDto | null> => {
  try {
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };
    const response = await apiInstance.get<OutputGetUserByIdDto>(`/user/${id}`, { headers });
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const updateUserById = async (
  id: number,
  input: InputUpdateUserByIdDto,
  accessToken: string,
) => {
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  try {
    const response = await apiInstance.patch(`/user/${id}/info`, input, {
      headers,
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const updateUserImageById = async (
  id: number,
  input: InputUpdateUserImageByIdDto,
  accessToken: string,
): Promise<any> => {
  try {
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };
    const response = await apiInstance.patch(`/user/${id}/image`, input, {
      headers,
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const updateUserDocumentById = async (
  id: number,
  input: InputUpdateUserDocumentByIdDto,
  accessToken: string,
): Promise<any> => {
  try {
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };
    const response = await apiInstance.patch(`/user/${id}/document`, input, {
      headers,
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const deleteUserById = async (id: number, accessToken: string) => {
  try {
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };
    const response = await apiInstance.delete(`/user/${id}`, { headers });
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getUserByEmail = async (email: string, accessToken: string) => {
  try {
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };
    const response = await apiInstance.get(`/user/email/${email}`, { headers });
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getUserByDocument = async (document: string, accessToken: string) => {
  try {
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };
    const response = await apiInstance.get(`/user/document/${document}`, { headers });
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getTicketsByUserId = async (
  id: number,
  accessToken: string,
): Promise<OutputGetTicketsByUserIdDto | null> => {
  try {
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };
    const response = await apiInstance.get<OutputGetTicketsByUserIdDto>(`/user/${id}/tickets`, {
      headers,
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const updateUserResponsesById = async <T = object>(
  id: number,
  input: { user_responses: T },
  accessToken: string,
) => {
  try {
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };
    const response = await apiInstance.patch(`/user/${id}/responses`, input, {
      headers,
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};
