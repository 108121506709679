import React from 'react';
import { IonIcon } from '@ionic/react';

import headerIcon from '../../../assets/header-icon.svg';

export const IconHeader = () => {
	return (
		<div style={{ display: 'flex', justifyContent: 'center', width: '100%', marginBottom: 30 }}>
			<IonIcon src={headerIcon} color='white' size='large' />
		</div>
	);
}
export default IconHeader;