import { apiInstance } from '../api';
import {
  InputCreateTicketDto,
  InputCreateTicketsBatchDto,
  InputUpdateTicketByIdDto,
  OutputGetTicketById,
} from './dto';

export const createTicket = async (input: InputCreateTicketDto, accessToken: string) => {
  try {
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };
    const response = await apiInstance.post('/ticket', input, { headers });
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getTickets = async (accessToken: string) => {
  try {
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };
    const response = await apiInstance.get('/ticket', { headers });
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const createTicketsBatch = async (
  input: InputCreateTicketsBatchDto,
  accessToken: string,
) => {
  try {
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };
    const response = await apiInstance.post('/ticket/batch', input, { headers });
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getTicketById = async (
  id: number,
  accessToken: string,
): Promise<OutputGetTicketById | null> => {
  try {
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };
    const response = await apiInstance.get(`/ticket/${id}`, { headers });
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const updateTicketById = async (
  id: string,
  input: InputUpdateTicketByIdDto,
  accessToken: string,
) => {
  try {
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };
    const response = await apiInstance.patch(`/ticket/${id}`, input, { headers });
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const deleteTicketById = async (id: number, accessToken: string) => {
  try {
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };
    const response = await apiInstance.delete(`/ticket/${id}`, { headers });
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};
