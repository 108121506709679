import React from 'react';
import './styles.css';

interface ResponsiveContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
}

const ResponsiveContainer = ({ children, ...props }: ResponsiveContainerProps) => {
  return (
    <div className="responsive-container" {...props}>
      {children}
    </div>
  );
};

export default ResponsiveContainer;
