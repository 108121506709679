import React, { useEffect, useState } from 'react';
import { IonButton, IonContent, IonIcon, IonPage, IonText, useIonRouter } from '@ionic/react';
import './style.css';
import { AppButton } from '../../components/App/Button';
import { AppTitle } from '../../components/App/Title';
import { AppInput } from '../../components/App/Input';
import { close } from 'ionicons/icons';
import { requestPasswordReset } from '../../api/auth';
import { useFormError } from '../../hooks/useFormError';
import { validateFieldsFilling } from '../../utils';
import { AppFormError } from '../../components/App/FormError';
import { useLoading } from '../../hooks/useLoading';
import { useQuery } from '../../hooks/useQuery';

const FIELDS = {
  email: 'email',
};

const ForgotPasswordScreen: React.FC = () => {
  const { changeLoadingStatus, loading } = useLoading();
  const [email, setEmail] = useState<string>('');
  const [submited, setSubmited] = useState<boolean>(false);
  const [resentEmail, setResentEmail] = useState(false);
  const router = useIonRouter();
  const {
    getFieldError,
    setFieldError,
    clearFieldsErrors,
    generalFormError,
    setGeneralFormError,
    clearGeneralFormError,
  } = useFormError({
    initialFields: [FIELDS.email],
  });

  const handleConfirm = async (): Promise<boolean> => {
    clearFieldsErrors();
    clearGeneralFormError();
    if (!email) {
      setFieldError(FIELDS.email, 'Campo obrigatório');
      return false;
    }
    const callbackUrl = `${window.location.origin}/reset-password`;
    changeLoadingStatus();
    const requestResponse = await requestPasswordReset(email, callbackUrl);
    changeLoadingStatus();
    if (!requestResponse) {
      setGeneralFormError('Erro ao solicitar recuperação de senha. Verifique o e-mail informado.');
      return false;
    }
    setSubmited(true);
    return true;
  };

  const handleResend = async () => {
    setResentEmail(false);
    const sentWithSuccess = await handleConfirm();
    setResentEmail(sentWithSuccess);
  };

  const handleBack = () => {
    router.push('/login', 'back');
  };

  return (
    <IonPage>
      <IonContent color={'dark'} fullscreen>
        <div className="recovery-flex-container">
          <div className="recovery-background-container"></div>

          <div className="recovery-action-container">
            <div
              style={{
                width: '40px',
              }}
            >
              <IonButton fill="clear" onClick={() => router.goBack()} className="back-button">
                <IonIcon icon={close}></IonIcon>
              </IonButton>
            </div>
            <div className="form-container">
              <AppTitle topTitle="Informe seus dados para" title="Recuperar a sua senha" />

              <div style={{ display: submited ? 'none' : '' }}>
                <div className="recovery-button-container">
                  <AppInput
                    required
                    label="Email"
                    placeholder="Informe seu e-mail"
                    onChange={(e) => setEmail(e.detail.value!)}
                    error={getFieldError(FIELDS.email)}
                    value={email}
                    type="email"
                  />
                </div>
                <AppButton loading={loading} label="Confirmar" onClick={handleConfirm} />
                {generalFormError && <AppFormError error={generalFormError} />}
              </div>
              <div style={{ display: submited ? '' : 'none' }}>
                <div className="recovery-button-container">
                  <IonText>
                    Um e-mail foi enviado para <span>{email}</span>. Por favor verifique a sua caixa
                    de entrada para trocar sua senha. Caso não tenha recebido em até 10 minutos
                    clique em reenviar e-mail
                  </IonText>
                </div>

                <AppButton
                  loading={loading}
                  label="Reenviar e-mail"
                  variant="secondary"
                  onClick={handleResend}
                />

                <div
                  className="recovery-button-container"
                  style={{ display: resentEmail ? '' : 'none' }}
                >
                  <IonText>E-mail reenviado com sucesso!</IonText>
                </div>
                {generalFormError && <AppFormError error={generalFormError} />}
              </div>

              <div style={{ width: '100%', textAlign: 'center', paddingTop: '16px' }}>
                <IonText className="login-link" onClick={handleBack}>
                  Já possui cadastro?{' '}
                  <IonText>
                    <span style={{ color: '#9ED0E6' }}>Fazer login!</span>
                  </IonText>
                </IonText>
              </div>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default ForgotPasswordScreen;
