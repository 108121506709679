import React from 'react';
import { InputChangeEventDetail, IonInput } from '@ionic/react';
import './styles.css';
import { useMaskito } from '@maskito/react';

export const phoneMask = {
  options: {
    mask: [
      '+',
      '55',
      ' ',
      '(',
      /\d/,
      /\d/,
      /\d/,
      ')',
      ' ',
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ],
  },
};

export const cpfMask = {
  options: {
    mask: [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/],
  },
};

const getMask = (mask: 'cpf' | 'phone' | undefined) => {
  if (mask === 'phone') {
    return phoneMask;
  } else if (mask === 'cpf') {
    return cpfMask;
  } else {
    return undefined;
  }
};

export const AppMaskedInput = ({
  label,
  placeholder,
  required = false,
  onChange,
  value,
  mask = undefined,
  type,
  error,
  ...props
}: {
  label: string;
  placeholder: string;
  required: boolean;
  mask?: 'cpf' | 'phone';
  type?: any;
  onChange: (event: CustomEvent<InputChangeEventDetail>) => void;
  value: string;
  error?: string;
}) => {
  const inputMask = useMaskito(getMask(mask));
  return (
    <IonInput
      label={label}
      labelPlacement="stacked"
      fill="solid"
      placeholder={placeholder}
      required={required}
      onIonInput={onChange}
      value={value}
      type={type}
      ref={async (phoneInput) => {
        if (phoneInput) {
          const input = await phoneInput.getInputElement();
          inputMask(input);
        }
      }}
      errorText={error}
      className={`app-input ${error ? 'app-input-error' : ''}`}
      {...props}
    />
  );
};
