import React from 'react';
import { IonIcon, IonText, useIonRouter } from '@ionic/react';
import { personCircleOutline } from 'ionicons/icons';
import headerIcon from '../../../assets/header-icon.svg';
import personIcon from '../../../assets/svg/profile.svg';
import facialIcon from '../../../assets/svg/facial-recognition.svg';
import logoutIcon from '../../../assets/svg/logout.svg';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { useEffect } from 'react';
import { getUserById } from '../../../api/User';
import {
  resetAccessToken,
  resetRefreshToken,
  resetUserId,
  resetUserName,
  setUserName,
} from '../../../redux/auth';
import { logout } from '../../../api/auth';

type InputAppHeaderProps = {
  shouldReloadUserInfo?: boolean;
};

export const AppHeader = ({ shouldReloadUserInfo }: InputAppHeaderProps) => {
  const router = useIonRouter();
  const authState = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchUserData = async () => {
      const userResponse = await getUserById(authState.userId!, authState.accessToken!);
      if (userResponse === null) {
        return;
      }
      if (userResponse.document === null) {
        router.push('/account-information', 'none');
      }
      dispatch(setUserName(userResponse.first_name));
    };
    const hasToReloadUserInfo = !authState.userName || shouldReloadUserInfo;
    if (hasToReloadUserInfo) {
      fetchUserData();
    }
  }, [shouldReloadUserInfo]);

  const handleProfile = () => {
    router.push('options', 'none');
  };

  const handleReviewMyData = () => {
    router.push('/personal', 'none');
  };

  const handleFacial = () => {
    router.push('/photo-option', 'none');
  };

  const handleLogout = () => {
    const callLogout = async () => {
      await logout(authState.accessToken!);
      dispatch(resetAccessToken());
      dispatch(resetRefreshToken());
      dispatch(resetUserId());
      dispatch(resetUserName());
      router.push('/', 'none');
    };
    callLogout();
  };

  return (
    <div className="header">
      <div></div>
      <IonIcon src={headerIcon} color="white" size="large" />
      <div className="profile-avatar-container">
        <IonIcon src={personCircleOutline} size="large" onClick={handleProfile} />
      </div>

      <div className="dropdown">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <IonIcon src={personCircleOutline} size="large" />
          <div className="name">
            <IonText>Olá, {authState?.userName ?? 'Usuário'}</IonText>
          </div>
        </div>

        <div className="dropdown-content">
          <span className="optionsItem" onClick={handleReviewMyData}>
            <IonIcon src={personIcon} style={{ fontSize: '16px' }} />
            <IonText>Revisar dados pessoais</IonText>
          </span>
          <div className="optionsItem" onClick={handleFacial}>
            <IonIcon src={facialIcon} style={{ fontSize: '16px' }} />
            <IonText>Realize seu reconhecimento facial</IonText>
          </div>
          <div className="optionsItem" onClick={handleLogout}>
            <IonIcon src={logoutIcon} style={{ fontSize: '16px' }} />
            <IonText>Logout</IonText>
          </div>
        </div>
      </div>
    </div>
  );
};
