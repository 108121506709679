import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import WelcomeScreen from './pages/Welcome';
import LoginScreen from './pages/Login';
import CreateAccountScreen from './pages/CreateAccount';
import AccountInformationScreen from './pages/AccountInformation';
import PhotoOptionWithSkipScreen from './pages/PhotoOptionWithSkip';
import PhotoInstructionsScreen from './pages/PhotoInstructions';
import PhotoCaptureScreen from './pages/PhotoCapture';
import VerifyPhotoScreen from './pages/PhotoVerify';
import HomeScreen from './pages/Home';
import MyTicketsScreen from './pages/MyTickets';
import MyTicketsDetailScreen from './pages/MyTicketsDetail';
import UserOptionsScreen from './pages/UserOptions';
import UserPersonalDataScreen from './pages/UserPersonalData';
import ForgotPasswordScreen from './pages/ForgotPassword';
import ResetPasswordScreen from './pages/ResetPassword';
import DocumentPhotosInstructionsScreen from './pages/DocumentPhotosInstructions';
import DocumentPhotosUploadScreen from './pages/DocumentPhotosUpload';
import SuccessfulRegistrationScreen from './pages/SuccessfulRegistration';
import AnswerQuestionsScreen from './pages/AnswerQuestions';
import DocumentCaptureScreen from './pages/DocumentCapture';

setupIonicReact({
  mode: 'ios',
});

const App: React.FC = () => (
  <IonApp>
    <IonReactRouter>
      <IonRouterOutlet>
        <Route path="/login" component={LoginScreen} />
        <Route path="/welcome" component={WelcomeScreen} />
        <Route path="/create-account" component={CreateAccountScreen} />
        <Route path="/account-information" component={AccountInformationScreen} />
        <Route path="/photo-option" component={PhotoOptionWithSkipScreen} />
        <Route path="/answer-questions" component={AnswerQuestionsScreen} />
        <Route path="/photo-instructions" component={PhotoInstructionsScreen} />
        <Route path="/photo-capture" component={PhotoCaptureScreen} />
        <Route path="/photo-verify" component={VerifyPhotoScreen} />
        <Route path="/document-photo-instructions" component={DocumentPhotosInstructionsScreen} />
        <Route path="/document-photos-upload" component={DocumentPhotosUploadScreen} />
        <Route path="/document-capture" component={DocumentCaptureScreen} />
        <Route path="/successful-registration" component={SuccessfulRegistrationScreen} />
        <Route path="/home" component={HomeScreen} />
        <Route path="/my-tickets" component={MyTicketsScreen} />
        <Route path="/my-tickets-detail/:id" component={MyTicketsDetailScreen} />
        <Route path="/options" component={UserOptionsScreen} />
        <Route path="/personal" component={UserPersonalDataScreen} />
        <Route path="/password-recovery" component={ForgotPasswordScreen} />
        <Route path="/reset-password" component={ResetPasswordScreen} />
        <Route exact path="/">
          <Redirect to="/welcome" />
        </Route>
      </IonRouterOutlet>
    </IonReactRouter>
  </IonApp>
);

export default App;
