// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./../../../public/assets/background-conffeti.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.successful-registration-background-container {
  --background: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background: var(--background);
  width: 100%;
  height: 100vh;
  display: flex;
  background-position: center;
  flex-direction: column;
}

.successful-registration-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  max-width: 400px;
  margin-bottom: 32px;
}

.successful-image {
  max-width: 13.375rem;
}

.successful-registration-text-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 0.375rem;
  background-color: #6d99fb26;
  padding: 1rem;
  border-radius: 1rem;
}

.successful-registration-text-title {
  font-weight: bold;
}

.successful-registration-text {
  line-height: 1.2rem;
}

@media (max-width: 500px) {
  .successful-registration-container {
    padding: 0 1.5rem 1rem;
    gap: 0.5rem;
  }
  .successful-registration-text {
    font-size: 0.875rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/SuccessfulRegistration/styles.css"],"names":[],"mappings":"AAAA;EACE,qDAAqE;EACrE,6BAA6B;EAC7B,WAAW;EACX,aAAa;EACb,aAAa;EACb,2BAA2B;EAC3B,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,SAAS;EACT,mBAAmB;EACnB,iBAAiB;EACjB,kBAAkB;EAClB,YAAY;EACZ,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,sBAAsB;EACtB,aAAa;EACb,2BAA2B;EAC3B,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE;IACE,sBAAsB;IACtB,WAAW;EACb;EACA;IACE,mBAAmB;EACrB;AACF","sourcesContent":[".successful-registration-background-container {\r\n  --background: url('./../../../public/assets/background-conffeti.png');\r\n  background: var(--background);\r\n  width: 100%;\r\n  height: 100vh;\r\n  display: flex;\r\n  background-position: center;\r\n  flex-direction: column;\r\n}\r\n\r\n.successful-registration-container {\r\n  display: flex;\r\n  flex-direction: column;\r\n  justify-content: center;\r\n  gap: 2rem;\r\n  align-items: center;\r\n  margin-left: auto;\r\n  margin-right: auto;\r\n  height: 100%;\r\n  max-width: 400px;\r\n  margin-bottom: 32px;\r\n}\r\n\r\n.successful-image {\r\n  max-width: 13.375rem;\r\n}\r\n\r\n.successful-registration-text-container {\r\n  display: flex;\r\n  justify-content: center;\r\n  flex-direction: column;\r\n  gap: 0.375rem;\r\n  background-color: #6d99fb26;\r\n  padding: 1rem;\r\n  border-radius: 1rem;\r\n}\r\n\r\n.successful-registration-text-title {\r\n  font-weight: bold;\r\n}\r\n\r\n.successful-registration-text {\r\n  line-height: 1.2rem;\r\n}\r\n\r\n@media (max-width: 500px) {\r\n  .successful-registration-container {\r\n    padding: 0 1.5rem 1rem;\r\n    gap: 0.5rem;\r\n  }\r\n  .successful-registration-text {\r\n    font-size: 0.875rem;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
