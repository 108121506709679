import React, { useEffect } from 'react';
import {
  IonContent,
  IonFooter,
  IonHeader,
  IonImg,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonRouter,
} from '@ionic/react';
import { AppButton } from '../../components/App/Button';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { resetImage } from '../../redux/signup';
import { updateUserImageById } from '../../api/User';
import { InputUpdateUserImageByIdDto } from '../../api/User/dto';
import { useLoading } from '../../hooks/useLoading';

// should receive image in base64 from state
const VerifyPhotoScreen: React.FC = () => {
  const signup = useSelector((state: RootState) => state.signup);
  const dispatch = useDispatch();
  const router = useIonRouter();
  const authData = useSelector((state: RootState) => state.auth);
  const { changeLoadingStatus, loading } = useLoading();

  const handleRetake = () => {
    dispatch(resetImage());
  };

  useEffect(() => {
    if (!signup.image) {
      router.push('/photo-capture');
    }
  }, [signup.image]);

  const handleContinue = async () => {
    changeLoadingStatus();
    const pictureBase64 = signup.image!.split(',')[1];
    const updateImageResponse = await updateUserImageById(
      authData.userId!,
      {
        picture: pictureBase64,
      },
      authData.accessToken!,
    );
    changeLoadingStatus();
    if (updateImageResponse === null) {
      console.log('Error updating image');
      return;
    }
    router.push('/document-photo-instructions');
  };

  return (
    <IonPage>
      <IonContent color={'dark'} fullscreen>
        <h1
          style={{
            backgroundColor: 'var(--app-color-on-secondary)',
            color: 'white',
            textAlign: 'center',
            margin: 0,
            padding: '10% 0 2rem',
          }}
        >
          A foto ficou boa?
        </h1>
        <div
          style={{
            width: '100%',
            height: '100%',
            backgroundColor: 'var(--app-color-on-secondary)',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'start',
            alignItems: 'center',
            padding: '1rem',
          }}
        >
          {signup.image && <IonImg src={signup.image} style={{ maxWidth: '800px' }} />}
          <AppButton
            style={{ width: '100%', maxWidth: '400px' }}
            label="Continuar"
            onClick={handleContinue}
            loading={loading}
          />
          <AppButton
            style={{ width: '100%', maxWidth: '400px' }}
            label="Refazer"
            variant="secondary"
            onClick={handleRetake}
          />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default VerifyPhotoScreen;
