// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* styles.css or variables.css */
.responsive-container {
	width: 100%; /* Full width on mobile */
	padding: 16px;
	box-sizing: border-box; /* Include padding in the width */
	margin: 0 auto; /* Center in the viewport */
  }
  
  /* Media query for larger screens - typically for web/desktop */
  @media (min-width: 500px) {
	.responsive-container {
	  max-width: 500px; /* Fixed max-width on desktop */
	}
  }`, "",{"version":3,"sources":["webpack://./src/components/App/ResponsiveContainer/styles.css"],"names":[],"mappings":"AAAA,gCAAgC;AAChC;CACC,WAAW,EAAE,yBAAyB;CACtC,aAAa;CACb,sBAAsB,EAAE,iCAAiC;CACzD,cAAc,EAAE,2BAA2B;EAC1C;;EAEA,+DAA+D;EAC/D;CACD;GACE,gBAAgB,EAAE,+BAA+B;CACnD;EACC","sourcesContent":["/* styles.css or variables.css */\r\n.responsive-container {\r\n\twidth: 100%; /* Full width on mobile */\r\n\tpadding: 16px;\r\n\tbox-sizing: border-box; /* Include padding in the width */\r\n\tmargin: 0 auto; /* Center in the viewport */\r\n  }\r\n  \r\n  /* Media query for larger screens - typically for web/desktop */\r\n  @media (min-width: 500px) {\r\n\t.responsive-container {\r\n\t  max-width: 500px; /* Fixed max-width on desktop */\r\n\t}\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
