import React, { useState } from 'react';
import {
  IonContent,
  IonIcon,
  IonImg,
  IonPage,
  IonText,
  IonTitle,
  useIonRouter,
} from '@ionic/react';
import './styles.css';
import { AppButton } from '../../components/App/Button';
import IconHeader from '../../components/App/IconHeader';
import { AppTitle } from '../../components/App/Title';
import PuppetFullBody from '../../assets/puppet-full-body.svg';
import { star } from 'ionicons/icons';

const SuccessfulRegistrationScreen: React.FC = () => {
  const router = useIonRouter();

  const handleContinue = () => {
    router.push('/my-tickets');
  };

  return (
    <IonPage>
      <IonContent color={'dark'} fullscreen>
        <div className="successful-registration-background-container">
          <div style={{ width: '100%', height: '100%', overflowY: 'scroll' }}>
            <div className="successful-registration-container">
              <IconHeader />
              <AppTitle
                topTitle="Seu cadastro foi concluído com sucesso!"
                title="Você já esta pronto para curtir seus eventos!"
              />
              <IonImg src={PuppetFullBody} className="successful-image" />
              <div className="successful-registration-text-container">
                <IonText class="successful-registration-text-title">
                  <IonIcon style={{ width: '1rem' }} icon={star} /> E agora?
                </IonText>
                <IonText class="successful-registration-text">
                  Agora é só esperar seu evento que nós cuidamos do resto! Caso tenha algum ingresso
                  comprado ele aparecerá em sua página de ingressos
                </IonText>
              </div>
              <AppButton style={{ width: '100%' }} label="Ok!" onClick={handleContinue} />
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default SuccessfulRegistrationScreen;
