// WelcomeScreen.tsx
import React from 'react';
import { IonContent, IonPage, IonText, useIonRouter } from '@ionic/react';
import './styles.css';
import { AppButton } from '../../components/App/Button';

const WelcomeScreen: React.FC = () => {
  const router = useIonRouter();

  const handleLogin = () => {
    // Handle login navigation
    router.push('/login', 'forward');
  };

  const handleCreateAccount = () => {
    // Handle create account navigation
    router.push('/create-account', 'forward');
  };

  return (
    <IonPage>
      <IonContent color={'dark'} fullscreen>
        <div className="welcome-flex-container">
          <div className="welcome-background-container"></div>
          <div className="welcome-action-container">
            <div className="form-container">
              <IonText className="welcome-text">
                <h1>Seja bem vindo ao</h1>
                <h2>OnEntrée</h2>
                <p>Somos o seu aplicativo para reconhecimento facial na catraca de eventos!</p>
              </IonText>
              <AppButton label="Login" onClick={handleLogin} />
              <AppButton label="Criar conta" variant="secondary" onClick={handleCreateAccount} />
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default WelcomeScreen;
