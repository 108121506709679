import React, { useState } from 'react';
import { IonContent, IonPage, useIonRouter } from '@ionic/react';
import './styles.css';
import { AppButton } from '../../components/App/Button';
import { AppInput } from '../../components/App/Input';
import { AppTitle } from '../../components/App/Title';
import IconHeader from '../../components/App/IconHeader';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { updateUserResponsesById } from '../../api/User';
import { useFormError } from '../../hooks/useFormError';
import { validateFieldsFilling } from '../../utils';
import { AppFormError } from '../../components/App/FormError';
import { useLoading } from '../../hooks/useLoading';

const FIELDS = {
  question1: 'question1',
  question2: 'question2',
};

const AnswerQuestionsScreen: React.FC = () => {
  const router = useIonRouter();
  const {
    generalFormError,
    setGeneralFormError,
    getFieldError,
    setFieldError,
    clearFieldsErrors,
    clearGeneralFormError,
  } = useFormError({
    initialFields: [FIELDS.question1, FIELDS.question2],
  });
  const userAuthData = useSelector((state: RootState) => state.auth);
  const { changeLoadingStatus, loading } = useLoading();
  const [question1, setQuestion1] = useState('');
  const [question2, setQuestion2] = useState('');

  const validateAllFieldsFilling = () => {
    clearFieldsErrors();
    clearGeneralFormError();
    return validateFieldsFilling(
      [
        { fieldId: FIELDS.question1, value: question1 },
        { fieldId: FIELDS.question2, value: question2 },
      ],
      setFieldError,
    );
  };

  const handleAnswerQuestions = async () => {
    const allFilled = validateAllFieldsFilling();
    if (!allFilled) {
      return;
    }
    changeLoadingStatus();
    const updateResponse = await updateUserResponsesById(
      userAuthData.userId!,
      {
        user_responses: {
          question1: question1,
          question2: question2,
        },
      },
      userAuthData.accessToken!,
    );
    changeLoadingStatus();
    if (updateResponse === null) {
      setGeneralFormError('Ocorreu um erro ao enviar as respostas.');
      return;
    }
    router.push('/photo-instructions');
  };

  return (
    <IonPage>
      <IonContent color={'dark'} fullscreen>
        <div className="register-flex-container">
          <div className="register-background-container"></div>
          <div className="register-action-container">
            <IconHeader />
            <div className="form-container">
              <AppTitle topTitle="Para utilizar o aplicativo" title="Realize seu cadastro" />
              <div className="register-button-container">
                <AppInput
                  label="Quanto você investe em Marketing Digital mensalmente?"
                  value={question1}
                  className="app-input-long-text"
                  onChange={(e) => setQuestion1(e.detail.value!)}
                  placeholder={'Informe o valor em Reais'}
                  type="number"
                  required
                  error={getFieldError(FIELDS.question1)}
                />
                <AppInput
                  label="Quanto você investe no TikTok mensalmente?*"
                  value={question2}
                  className="app-input-long-text"
                  onChange={(e) => setQuestion2(e.detail.value!)}
                  placeholder="Informe o valor em Reais"
                  required
                  type="number"
                  error={getFieldError(FIELDS.question2)}
                />
              </div>
              <AppButton label="Confirmar" onClick={handleAnswerQuestions} loading={loading} />
              {generalFormError && <AppFormError error={generalFormError} />}
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default AnswerQuestionsScreen;
