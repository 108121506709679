import React, { useRef, useState } from 'react';
import Webcam from 'react-webcam';
// import cv from 'opencv';
import './styles.css';
import { AppButton } from '../App/Button';

const DocumentScanner: React.FC<{
  onCapture: (croppedImage: string) => void;
  label: string;
  className?: string;
}> = ({ onCapture, label, className }) => {
  const webcamRef = useRef<Webcam>(null);
  // const [capturedImage, setCapturedImage] = useState<string | null>(null);

  const captureAndCrop = async () => {
    if (!webcamRef.current) return;
    const imageSrc = webcamRef.current.getScreenshot();
    if (!imageSrc) return;

    // setCapturedImage(imageSrc);
    // const croppedImage = await cropDocument(imageSrc);
    onCapture(imageSrc);
  };

  // const cropDocument = async (imageSrc: string): Promise<string> => {
  // 	return new Promise((resolve) => {
  // 		const img = new Image();
  // 		img.src = imageSrc;

  // 		img.onload = () => {
  // 			const srcMat = cv.imread(img);
  // 			const grayMat = new cv.Mat();
  // 			cv.cvtColor(srcMat, grayMat, cv.COLOR_RGBA2GRAY, 0);

  // 			const blurredMat = new cv.Mat();
  // 			cv.GaussianBlur(grayMat, blurredMat, new cv.Size(5, 5), 0);

  // 			const edgedMat = new cv.Mat();
  // 			cv.Canny(blurredMat, edgedMat, 75, 200);

  // 			const contours = new cv.MatVector();
  // 			const hierarchy = new cv.Mat();
  // 			cv.findContours(edgedMat, contours, hierarchy, cv.RETR_LIST, cv.CHAIN_APPROX_SIMPLE);

  // 			let documentContour = null;
  // 			for (let i = 0; i < contours.size(); i++) {
  // 				const contour = contours.get(i);
  // 				const peri = cv.arcLength(contour, true);
  // 				const approx = new cv.Mat();
  // 				cv.approxPolyDP(contour, approx, 0.02 * peri, true);
  // 				if (approx.size().height === 4) {
  // 					documentContour = approx;
  // 					break;
  // 				}
  // 			}

  // 			if (documentContour) {
  // 				const points = [];
  // 				for (let i = 0; i < 4; i++) {
  // 					points.push({
  // 						x: documentContour.data32S[i * 2],
  // 						y: documentContour.data32S[i * 2 + 1],
  // 					});
  // 				}

  // 				points.sort((a, b) => a.y - b.y);
  // 				const [topLeft, topRight] = points.slice(0, 2).sort((a, b) => a.x - b.x);
  // 				const [bottomLeft, bottomRight] = points.slice(2).sort((a, b) => a.x - b.x);

  // 				const widthTop = Math.hypot(topRight.x - topLeft.x, topRight.y - topLeft.y);
  // 				const widthBottom = Math.hypot(bottomRight.x - bottomLeft.x, bottomRight.y - bottomLeft.y);
  // 				const maxWidth = Math.max(widthTop, widthBottom);

  // 				const heightLeft = Math.hypot(topLeft.x - bottomLeft.x, topLeft.y - bottomLeft.y);
  // 				const heightRight = Math.hypot(topRight.x - bottomRight.x, topRight.y - bottomRight.y);
  // 				const maxHeight = Math.max(heightLeft, heightRight);

  // 				const srcTri = cv.matFromArray(4, 1, cv.CV_32FC2, [
  // 					topLeft.x, topLeft.y,
  // 					topRight.x, topRight.y,
  // 					bottomRight.x, bottomRight.y,
  // 					bottomLeft.x, bottomLeft.y,
  // 				]);
  // 				const dstTri = cv.matFromArray(4, 1, cv.CV_32FC2, [
  // 					0, 0,
  // 					maxWidth, 0,
  // 					maxWidth, maxHeight,
  // 					0, maxHeight,
  // 				]);

  // 				const M = cv.getPerspectiveTransform(srcTri, dstTri);
  // 				const dst = new cv.Mat();
  // 				const dsize = new cv.Size(maxWidth, maxHeight);
  // 				cv.warpPerspective(srcMat, dst, M, dsize, cv.INTER_LINEAR, cv.BORDER_CONSTANT, new cv.Scalar());

  // 				cv.imshow('outputCanvas', dst);

  // 				const canvas = document.getElementById('outputCanvas') as HTMLCanvasElement;
  // 				const croppedImage = canvas.toDataURL('image/jpeg');
  // 				resolve(croppedImage);

  // 				srcMat.delete();
  // 				grayMat.delete();
  // 				blurredMat.delete();
  // 				edgedMat.delete();
  // 				contours.delete();
  // 				hierarchy.delete();
  // 				dst.delete();
  // 				M.delete();
  // 				srcTri.delete();
  // 				dstTri.delete();
  // 			} else {
  // 				resolve(imageSrc); // If no document is found, return the original image
  // 			}
  // 		};
  // 	});
  // };

  return (
    <div
      className={`doc-scanner-container ${className ?? ''}`}
      style={{ position: 'relative', textAlign: 'center' }}
    >
      <h1>{label}</h1>
      <div style={{ position: 'relative' }}>
        <Webcam
          audio={false}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          className="input-video"
          style={{ width: '100%' }}
        />
        <canvas id="outputCanvas" style={{ display: 'none' }}></canvas>
        <div id="rectangle" className="document-rectangle-shape"></div>
      </div>
      <AppButton
        label="Capturar"
        onClick={captureAndCrop}
        style={{
          position: 'absolute',
          bottom: '2%',
          width: '100%',
          left: 0,
          right: 0,
          marginLeft: 'auto',
          marginRight: 'auto',
          maxWidth: '330px',
          zIndex: 3,
        }}
      />
    </div>
  );
};

export default DocumentScanner;
