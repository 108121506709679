import { apiInstance } from '../api';
import { InputCreateLocationDto, InputUpdateLocationByIdDto, OutputGetAllLocations } from './dto';

export const createLocation = async (input: InputCreateLocationDto, accessToken: string) => {
  try {
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };
    const response = await apiInstance.post('/location', input, { headers });
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getLocations = async (accessToken: string): Promise<OutputGetAllLocations | null> => {
  try {
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };
    const response = await apiInstance.get('/location', { headers });
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getLocationById = async (id: number, accessToken: string) => {
  try {
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };
    const response = await apiInstance.get(`/location/${id}`, { headers });
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const updateLocationById = async (
  id: string,
  input: InputUpdateLocationByIdDto,
  accessToken: string,
) => {
  try {
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };
    const response = await apiInstance.patch(`/location/${id}`, input, { headers });
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const deleteLocationById = async (id: number, accessToken: string) => {
  try {
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };
    const response = await apiInstance.delete(`/location/${id}`, { headers });
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};
