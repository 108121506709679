import React, { useEffect } from 'react';
import {
  IonContent,
  IonFooter,
  IonHeader,
  IonPage,
  IonText,
  IonTitle,
  IonToolbar,
  useIonRouter,
} from '@ionic/react';
import FaceCapture from '../../components/FaceCapture';
import { AppButton } from '../../components/App/Button';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../redux/store';
import { setImage } from '../../redux/signup';

const PhotoCaptureScreen: React.FC = () => {
  const signup = useSelector((state: RootState) => state.signup);
  const dipsatch = useDispatch();

  const onCapture = (image: string) => {
    // receives img in base64
    dipsatch(setImage(image));
  };

  const router = useIonRouter();

  useEffect(() => {
    if (signup.image) {
      router.push('/photo-verify');
    }
  }, [signup.image]);

  return (
    <IonPage>
      <IonContent color={'dark'} fullscreen>
        <h1
          style={{
            backgroundColor: 'var(--app-color-on-secondary)',
            color: 'white',
            textAlign: 'center',
            margin: 0,
            padding: '10% 0 2rem',
          }}
        >
          Centralize seu rosto
        </h1>
        <div
          style={{
            display: 'flex',
            justifyContent: 'start',
            paddingTop: '2rem',
            alignItems: 'center',
            flexDirection: 'column',
            backgroundColor: 'var(--app-color-on-secondary)',
            height: '100%',
          }}
        >
          <FaceCapture onCapture={onCapture} />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default PhotoCaptureScreen;
