import React, { useEffect, useRef, useState } from 'react';
import { IonButton, IonItem, IonLabel, IonList, IonModal } from '@ionic/react';
import AppTypeahead from './AppTypeahead';
import type { SelectItem } from './types';
import ResponsiveContainer from '../ResponsiveContainer';
import { AppInput } from '../Input';

type SelectProps = {
  items: SelectItem[];
  label: string;
  placeholder: string;
  onChange?: () => void;
  disabled?: boolean;
  selectedItem: string;
  setSelectedItem: (value: string) => void;
  error?: string;
};

const AppSelect = ({
  items,
  label,
  onChange,
  placeholder,
  disabled = false,
  selectedItem,
  setSelectedItem,
  error,
}: SelectProps) => {
  const [selectedItemText, setSelectedItemText] = useState<string>('');

  useEffect(() => {
    if (selectedItem) return;
    itemSelectionChanged('');
  }, [selectedItem]);

  const formatData = (selected: string) => {
    if (!selected) return placeholder;
    const item = items.find((item) => item.value === selected)!;
    return item.text;
  };

  const modal = useRef<HTMLIonModalElement>(null);

  const itemSelectionChanged = (item: string) => {
    onChange?.();
    setSelectedItem(item);
    setSelectedItemText(formatData(item));
    modal.current?.dismiss();
  };

  return (
    <div>
      <AppInput
        label={label}
        onChange={() => {
          console.log('changed');
        }}
        onClick={() => {
          modal.current?.present();
        }}
        placeholder={placeholder}
        required
        value={selectedItemText}
        id="select-items"
        readonly={true}
        disabled={disabled}
        error={error}
      />
      <IonModal ref={modal}>
        <AppTypeahead
          title={placeholder}
          items={items}
          selectedItem={selectedItem}
          onSelectionCancel={() => modal.current?.dismiss()}
          onSelectionChange={itemSelectionChanged}
        />
      </IonModal>
    </div>
  );
};
export default AppSelect;
